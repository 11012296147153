import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withAuth from '../auth/withAuth';

import Logo from '../img/logo_50.png';
import Link from './Link';

class Header extends Component {
  state = {
    userMenu: null,
  };

  closeMenu = (key) => {
    this.setState({
      [key]: null,
    });
  };

  render() {
    const { userMenu } = this.state;
    const { user, logout } = this.props;

    return (
      <AppBar style={{ backgroundColor: '#FFFFFF' }} color="inherit" position="static">
        <Toolbar style={{ minHeight: '50px', justifyContent: 'space-between' }}>
          <img src={Logo} height="30" width="165" alt="Nutritoo logo" />
          <div
            style={{ cursor: 'pointer' }}
            onClick={(event) => {
              this.setState({ userMenu: event.currentTarget });
            }}
            role="button"
            tabIndex={0}
            onKeyPress={null}
          >
            <span style={{ marginRight: 2 }}>{user.name}</span>
            <IconButton
              aria-label="User Options"
              style={{ padding: '0' }}
              className="menu-icon-btn"
              onClick={(event) => {
                this.setState({ userMenu: event.currentTarget });
              }}
            >
              <ArrowDropDownIcon />
            </IconButton>
          </div>
          <Menu
            id="user-menu"
            anchorEl={userMenu}
            open={Boolean(userMenu)}
            onClose={() => {
              this.closeMenu('userMenu');
            }}
            style={{
              marginTop: 25,
            }}
          >
            {user.role !== 'receptionist' ? (
              <Link to="/receptionist" target="_blank">
                <MenuItem onClick={() => this.closeMenu('userMenu')}>Receptionist</MenuItem>
              </Link>
            ) : null}
            {user.role !== 'receptionist' ? (
              <Link to="/doctor" target="_blank">
                <MenuItem onClick={() => this.closeMenu('userMenu')}>Doctor</MenuItem>
              </Link>
            ) : null}
            {user.role === 'admin' ? (
              <Link to="/admin">
                <MenuItem onClick={() => this.closeMenu('userMenu')}>Admin</MenuItem>
              </Link>
            ) : null}
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
  logout: PropTypes.func.isRequired,
};

export default withAuth(false)(Header);
