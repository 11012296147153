import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Button } from 'antd';
import { Mutation } from 'react-apollo';

import UpdateSmsServiceMutation from '../graphql/v2/mutations/updateSmsServiceMutation.graphql';

const UpdateSmsServiceComponent = ({ subscription }) => (
  <Mutation
    mutation={UpdateSmsServiceMutation}
    variables={{
      subscriptionId: subscription.id,
      sendDietPlanSms: !subscription.sendDietPlanSms,
    }}
    onError={(mutErr) => {
      Sentry.captureException(mutErr);
    }}
    context={{ headers: { 'x-hasura-role': 'receptionist' } }}
    refetchQueries={['anomalySubscriptions']}
  >
    {(updateSmsService, { loading: mutLoading }) => (
      <Button htmlType="button" onClick={updateSmsService} loading={mutLoading}>
        {subscription.sendDietPlanSms ? 'Stop SMS Service' : 'Resume SMS Service'}
      </Button>
    )}
  </Mutation>
);

UpdateSmsServiceComponent.propTypes = {
  subscription: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sendDietPlanSms: PropTypes.bool.isRequired,
  }).isRequired,
};

export default UpdateSmsServiceComponent;
