import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { Query } from 'react-apollo';
import { Spin } from 'antd';
import Paper from '@material-ui/core/Paper/Paper';
import Select from 'react-select';

import QUERY from '../graphql/v2/queries/allPatientsQuery.graphql';
import PatientPage from './PatientPage';

class AllPatientsPage extends Component {
  state = {
    selectedPatientOption: null,
  };

  render() {
    const { selectedPatientOption } = this.state;

    return (
      <Query
        query={QUERY}
        fetchPolicy="network-only"
        onError={(qErr) => {
          Sentry.captureException(qErr);
        }}
        context={{ headers: { 'x-hasura-role': 'app_admin' } }}
      >
        {({ loading, error, data }) => {
          if (loading) return <Spin size="large" />;
          if (error) return error.message;

          const { allPatients } = data;

          return (
            <div style={{ padding: 20 }}>
              <Select
                isClearable
                isSearchable
                value={selectedPatientOption}
                onChange={(selectedOption) => {
                  this.setState({ selectedPatientOption: selectedOption });
                }}
                options={allPatients.map((patient) => ({
                  label: `${patient.name} (${patient.mobile})`,
                  value: patient.id,
                }))}
              />
              {selectedPatientOption ? (
                <Paper style={{ marginTop: 10, padding: 20 }}>
                  <PatientPage patientId={selectedPatientOption.value} />
                </Paper>
              ) : null}
            </div>
          );
        }}
      </Query>
    );
  }
}

export default AllPatientsPage;
