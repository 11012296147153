import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { graphql } from 'react-apollo';
import * as compose from 'lodash/flowRight';
import { Form, Input, InputNumber, Button, message, Select, Checkbox } from 'antd';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { units } from '../utils/globals';

import MUTATION from '../graphql/v2/mutations/newIngredientMutation.graphql';

const FormItem = Form.Item;
const { Option } = Select;

class NewIngredientForm extends Component {
  state = {
    submittingForm: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      submittingForm: true,
    });

    const { form, mutate } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        mutate({
          variables: { ...values, tags: values.tags.map((tag) => JSON.stringify(tag)) },
          context: { headers: { 'x-hasura-role': 'doctor' } },
        })
          .then(() => {
            this.setState({
              submittingForm: false,
            });
            form.resetFields();
            message.success('Ingredient added!');
          })
          .catch((mutateErr) => {
            console.log(mutateErr);
            this.setState({
              submittingForm: false,
            });
            message.error(mutateErr.message);
            Sentry.captureException(mutateErr);
          });
      } else {
        this.setState({
          submittingForm: false,
        });
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { submittingForm } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem hasFeedback label="Ingredient name">
          {getFieldDecorator('name_en', {
            rules: [
              {
                required: true,
                message: 'First name is required!',
              },
            ],
          })(<Input type="text" required disabled={submittingForm} />)}
        </FormItem>
        <FormItem hasFeedback label="Quantity Unit">
          {getFieldDecorator('qtyUnit', {
            rules: [
              {
                required: true,
                message: 'First name is required!',
              },
            ],
          })(
            <Select disabled={submittingForm} showSearch>
              {units.map((entry) => (
                <Option value={entry.key} key={entry.key}>
                  {entry.name} ({entry.key})
                </Option>
              ))}
            </Select>,
          )}
        </FormItem>
        <FormItem hasFeedback label="Standard Quantity">
          {getFieldDecorator('stdQty', {
            rules: [
              {
                required: true,
                message: 'First name is required!',
              },
              {
                pattern: validationRegex.float,
                message: 'Incorrect value!',
              },
            ],
          })(<InputNumber required disabled={submittingForm} />)}
        </FormItem>
        <FormItem hasFeedback label="Carbohydrates">
          {getFieldDecorator('carbs', {
            rules: [
              {
                required: true,
                message: 'First name is required!',
              },
              {
                pattern: validationRegex.float,
                message: 'Incorrect value!',
              },
            ],
          })(<InputNumber required disabled={submittingForm} />)}
        </FormItem>
        <FormItem hasFeedback label="Fats">
          {getFieldDecorator('fats', {
            rules: [
              {
                required: true,
                message: 'First name is required!',
              },
              {
                pattern: validationRegex.float,
                message: 'Incorrect value!',
              },
            ],
          })(<InputNumber required disabled={submittingForm} />)}
        </FormItem>
        <FormItem hasFeedback label="Proteins">
          {getFieldDecorator('proteins', {
            rules: [
              {
                required: true,
                message: 'First name is required!',
              },
              {
                pattern: validationRegex.float,
                message: 'Incorrect value!',
              },
            ],
          })(<InputNumber required disabled={submittingForm} />)}
        </FormItem>
        <FormItem hasFeedback label="Calories (kcal)">
          {getFieldDecorator('kcal', {
            rules: [
              {
                required: true,
                message: 'First name is required!',
              },
              {
                pattern: validationRegex.float,
                message: 'Incorrect value!',
              },
            ],
          })(<InputNumber required disabled={submittingForm} />)}
        </FormItem>
        {/* <FormItem label="Tags"> */}
        {/* {getFieldDecorator('tags')( */}
        {/* <Select disabled={submittingForm} mode="tags" />, */}
        {/* )} */}
        {/* </FormItem> */}
        <FormItem label="Is also a recipe?">
          {getFieldDecorator('isRecipe')(
            <Checkbox disabled={submittingForm}>
              Check if this ingredient is also a recipe
            </Checkbox>,
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" loading={submittingForm}>
            Submit
          </Button>
        </FormItem>
      </Form>
    );
  }
}

NewIngredientForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default compose(graphql(MUTATION), Form.create())(NewIngredientForm);
