import React from 'react';
import { AuthConsumer } from './authContext';

const withUserRole = (Component) =>
  function (props) {
    return (
      <AuthConsumer>{({ user }) => <Component {...props} userRole={user.role} />}</AuthConsumer>
    );
  };

export default withUserRole;
