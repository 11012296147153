import { createContext } from 'react';

const authContext = createContext({
  loading: true,
  userLoggedIn: false,
  user: null,
  token: null,
  login: null,
  logout: null,
});

export const AuthProvider = authContext.Provider;
export const AuthConsumer = authContext.Consumer;
