class AuthToken {
  token = '';

  getToken = () => this.token;

  setToken = (token) => {
    this.token = token;
  };

  removeToken = () => {
    this.token = '';
  };
}

const authToken = new AuthToken();

export default authToken;
