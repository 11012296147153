import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { from } from 'apollo-link';
import localforage from 'localforage';
import * as Sentry from '@sentry/browser';
import { log } from '../utils/globals';
import authToken from '../auth/authToken';

const apolloCache = new InMemoryCache();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext(async (_, { headers }) => {
  const token = authToken.getToken();

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  }
  return headers;
});

const errorLink = onError(({ graphQLErrors }) => {
  if (
    Array.isArray(graphQLErrors) &&
    graphQLErrors.length > 0 &&
    graphQLErrors.find((ele) => ele.extensions.code === 'access-denied')
  ) {
    /** logging user off */
    /**  Clear local storage */
    localforage.clear().catch((err) => {
      log(err);
      Sentry.captureException(err);
    });

    // navigating the screen to login page
    window.location.href = '/login';
  }
});

export default new ApolloClient({
  cache: apolloCache,
  link: from([errorLink, authLink, httpLink]),
});
