import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Button, Popconfirm, Table, Input, Icon } from 'antd';
import moment from 'moment';
import { Mutation, Query } from 'react-apollo';
import withUserRole from '../auth/withUserRole';
import Link from './Link';
import UpdateSmsServiceComponent from './UpdateSmsServiceComponent';
import anomalySubscriptionsQuery from '../graphql/v2/queries/anomalySubscriptionsQuery.graphql';
import PauseSubscriptionMutation from '../graphql/v2/mutations/pauseSubscriptionMutation.graphql';
import ResumeSubscriptionMutation from '../graphql/v2/mutations/resumeSubscriptionMutation.graphql';
import StopSubscriptionMutation from '../graphql/v2/mutations/stopSubscriptionMutation.graphql';

const { Column } = Table;

const handleSearch = (selectedKeys, confirm) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters();
};

const FilterDropdownComponent = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  name,
}) => (
  <div style={{ padding: 8 }}>
    <Input
      placeholder={`Search by ${name}`}
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => handleSearch(selectedKeys, confirm)}
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
    <Button
      type="button"
      onClick={() => handleSearch(selectedKeys, confirm)}
      icon="search"
      size="small"
      style={{ width: 90, marginRight: 8 }}
    >
      Search
    </Button>
    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
      Reset
    </Button>
  </div>
);

const getColumnSearchProps = (dataIndex, name) => ({
  filterDropdown: (props) => <FilterDropdownComponent name={name} {...props} />,
  filterIcon: (filtered) => (
    <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value, record) =>
    record.patient[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
});

const AnamolySubscriptionsTable = ({ userRole }) => (
  <Query
    query={anomalySubscriptionsQuery}
    variables={{
      startDate: moment().subtract(15, 'days').startOf('day'),
    }}
    context={{ headers: { 'x-hasura-role': 'receptionist' } }}
    fetchPolicy="network-only"
    onError={(qErr) => {
      Sentry.captureException(qErr);
    }}
  >
    {({ loading, error, data }) => {
      if (error) return <p>ERROR: {error.message}</p>;
      return (
        <Table
          loading={loading}
          dataSource={
            data && Array.isArray(data.anomalySubscriptions) && data.anomalySubscriptions.length > 0
              ? data.anomalySubscriptions.filter((i) => i.dietPlans.length > 0)
              : []
          }
          rowKey="id"
          pagination={false}
          bordered
        >
          <Column
            title="Patient"
            key="patient"
            render={(text, record) => (
              <Fragment>
                <p>{record.patient.name}</p>
                <p>{record.patient.mobile}</p>
              </Fragment>
            )}
            {...getColumnSearchProps('name', 'name')}
          />
          <Column title="Status" key="status" dataIndex="status" />
          <Column
            title="Start Date"
            key="start"
            render={(text, record) => {
              if (record.startDate) {
                return moment(new Date(record.startDate)).format('Do MMM, YYYY');
              }
              return null;
            }}
          />
          <Column title="Total Plans" key="dietPlansCount" dataIndex="dietPlansCount" />
          <Column
            title="SMS Service"
            key="sms"
            render={(text, record) => (record.sendDietPlanSms ? 'ON' : 'OFF')}
            filters={[
              {
                text: 'ON',
                value: true,
              },
              {
                text: 'OFF',
                value: false,
              },
            ]}
            onFilter={(value, record) => {
              if (record.sendDietPlanSms === value) {
                return record;
              }
              return null;
            }}
          />
          <Column
            title="Current Diet Plan"
            key="cdp"
            render={(text, record) => (
              <Fragment>
                <p>
                  <strong>Start Date: </strong>
                  {moment(new Date(record.dietPlans[0].startDate)).format('Do MMM')}
                </p>
                <p>
                  <strong>No. of days: </strong>
                  {moment().diff(moment(new Date(record.dietPlans[0].startDate)), 'days')} days
                </p>
              </Fragment>
            )}
          />
          <Column
            title="Actions"
            key="actions"
            render={(text, record) => (
              <div>
                {userRole !== 'receptionist' ? (
                  <Link to={`/doctor/subscription/${record.id}`} target="_blank">
                    <Button htmlType="button">View</Button>
                  </Link>
                ) : null}
                {record.status === 'on' ? (
                  <UpdateSmsServiceComponent subscription={record} />
                ) : null}
                {/* Pause Subscription */}
                {userRole !== 'receptionist' && record.status === 'on' ? (
                  <Mutation
                    mutation={PauseSubscriptionMutation}
                    refetchQueries={['anomalySubscriptions']}
                    variables={{ id: record.id }}
                    onError={(mutErr) => {
                      Sentry.captureException(mutErr);
                    }}
                    context={{ headers: { 'x-hasura-role': 'doctor' } }}
                  >
                    {(pauseSubscription, { loading: mutLoading }) => (
                      <Button htmlType="button" onClick={pauseSubscription} loading={mutLoading}>
                        Pause Subscription
                      </Button>
                    )}
                  </Mutation>
                ) : null}
                {/* Resume paused subscription */}
                {userRole !== 'receptionist' && record.status === 'paused' ? (
                  <Mutation
                    mutation={ResumeSubscriptionMutation}
                    refetchQueries={['patientSubscriptions']}
                    variables={{ id: record.id }}
                    onError={(mutErr) => {
                      Sentry.captureException(mutErr);
                    }}
                    context={{ headers: { 'x-hasura-role': 'doctor' } }}
                  >
                    {(resumeSubscription) => (
                      <Button htmlType="button" onClick={resumeSubscription}>
                        Resume Subscription
                      </Button>
                    )}
                  </Mutation>
                ) : null}
                {/* Stop Subscription action button */}
                {userRole !== 'receptionist' &&
                record.status !== 'ended' &&
                record.status !== 'stopped' ? (
                  <Mutation
                    mutation={StopSubscriptionMutation}
                    refetchQueries={['patientSubscriptions']}
                    variables={{ id: record.id }}
                    onError={(mutErr) => {
                      Sentry.captureException(mutErr);
                    }}
                    context={{ headers: { 'x-hasura-role': 'doctor' } }}
                  >
                    {(stopSubscription, { loading: mutLoading }) => (
                      <Popconfirm
                        title={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <span>
                            Are you sure you want to stop subscription?
                            <br />
                            <span style={{ color: 'red', fontWeight: 'bold' }}>
                              CANNOT BE UNDONE
                            </span>
                          </span>
                        }
                        onConfirm={stopSubscription}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button htmlType="button" loading={mutLoading}>
                          Stop Subscription
                        </Button>
                      </Popconfirm>
                    )}
                  </Mutation>
                ) : null}
              </div>
            )}
          />
        </Table>
      );
    }}
  </Query>
);

AnamolySubscriptionsTable.propTypes = {
  userRole: PropTypes.string.isRequired,
};

FilterDropdownComponent.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  confirm: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default withUserRole(AnamolySubscriptionsTable);
