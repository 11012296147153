import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from 'antd';
import { medicalProblemsObj } from '../utils/globals';
import Logo from '../img/logo.png';

import './ViewDietPlan.scss';

const ViewDietPlan = ({ dietPlan, patient }) => (
  <div id="dietPlanView">
    <Button
      htmlType="button"
      type="primary"
      onClick={() => window.print()}
      className="hideInPrint"
      style={{ margin: 10 }}
    >
      Print
    </Button>
    <div id="dietPlanViewHeader">
      <img src={Logo} style={{ width: 155, height: 38 }} alt="Nutritoo logo" />
      <h1>DIET CHART - {dietPlan.subscriptionSequencePosition}</h1>
      <h4 style={{ textAlign: 'right' }}>
        {patient.name} <br /> +91 {patient.mobile}
      </h4>
    </div>
    <table id="dietPlanViewBasicDetailsTable">
      <tbody>
        <tr>
          <th>Date</th>
          <td>{moment().format('Do MMM, YYYY')}</td>
          <th>Weight</th>
          <td>{dietPlan.weight} kgs</td>
        </tr>
        <tr />
        <tr>
          <th>Fitness Goals</th>
          <td>{dietPlan.fitnessGoals.join(', ')}</td>
          <th>Workout Time</th>
          <td>
            {moment({ hours: dietPlan.woTimeHours, minutes: dietPlan.woTimeMinutes }).format(
              'hh:mm A',
            )}
          </td>
        </tr>
        <tr />
        <tr>
          <th>Medical Problems</th>
          <td>
            {dietPlan.medicalProblems &&
            Array.isArray(dietPlan.medicalProblems) &&
            dietPlan.medicalProblems.length > 0
              ? dietPlan.medicalProblems.map((entry) => medicalProblemsObj[entry]).join(', ')
              : 'None'}
          </td>
          <th>Diet Type</th>
          <td>{dietPlan.dietType}</td>
        </tr>
        <tr />
      </tbody>
    </table>

    <table id="dietPlanViewDietChartTable">
      <thead>
        <tr>
          <th id="dietPlanViewDietChartTableCol1">Time</th>
          <th id="dietPlanViewDietChartTableCol2">Meals</th>
          <th id="dietPlanViewDietChartTableCol3">Supplements</th>
        </tr>
      </thead>
      <tbody>
        {dietPlan.entries.map((entry) => (
          <tr key={entry.id}>
            <td>
              <p className="dietPlanViewDietChartTableTime">
                {moment({ hours: entry.hours, minutes: entry.minutes }).format('hh:mm A')}
              </p>
              <p>{entry.mealType}</p>
              <p>{entry.timeNote}</p>
            </td>
            <td>
              {entry.meals.map((entry1, index) => {
                if (index > 0) {
                  return [
                    <p style={{ margin: 0 }} key={`${index}Plus`}>
                      +
                    </p>,
                    <p style={{ margin: 0 }} key={`${index}Value`}>
                      {entry1.join(' / ')}
                    </p>,
                  ];
                }
                return (
                  <p key={`${index}Value`} style={{ margin: 0 }}>
                    {entry1.join(' / ')}
                  </p>
                );
              })}
            </td>
            <td>
              {entry.supplements.map((entry1, index) => {
                if (index > 0) {
                  return [
                    <p style={{ margin: 0 }} key={`${index}Plus`}>
                      +
                    </p>,
                    <p style={{ margin: 0 }} key={`${index}Value`}>
                      {entry1}
                    </p>,
                  ];
                }
                return (
                  <p key={`${index}Value`} style={{ margin: 0 }}>
                    {entry1}
                  </p>
                );
              })}
            </td>
          </tr>
        ))}
      </tbody>
    </table>

    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <p>
        <strong>Water Intake: </strong>
        {dietPlan.waterIntake} lit / day
      </p>
      <p>
        <strong>Oil Intake: </strong>
        {dietPlan.oilIntake} gm / day
      </p>
      <p>
        <strong>Best Oil: </strong>
        Olive Oil / Rice Bran Oil
      </p>
    </div>

    <h3>General Guidelines</h3>
    <ol>
      {dietPlan.generalGuidelines.map((entry2) => (
        <li key={entry2}>{entry2}</li>
      ))}
    </ol>

    <h3>Special Guidelines</h3>
    <ol>
      {dietPlan.specialGuidelines.map((entry2) => (
        <li key={entry2}>{entry2}</li>
      ))}
    </ol>

    <Button
      htmlType="button"
      type="primary"
      onClick={() => window.print()}
      className="hideInPrint"
      style={{ margin: 10 }}
    >
      Print
    </Button>
  </div>
);

ViewDietPlan.propTypes = {
  dietPlan: PropTypes.shape({
    id: PropTypes.number.isRequired,
    subscriptionSequencePosition: PropTypes.number.isRequired,
    weight: PropTypes.number.isRequired,
    woTimeHours: PropTypes.string.isRequired,
    woTimeMinutes: PropTypes.string.isRequired,
    dietType: PropTypes.string.isRequired,
    waterIntake: PropTypes.number.isRequired,
    oilIntake: PropTypes.number.isRequired,
    generalGuidelines: PropTypes.array.isRequired,
    specialGuidelines: PropTypes.array.isRequired,
    medicalProblems: PropTypes.array.isRequired,
    fitnessGoals: PropTypes.array.isRequired,
    entries: PropTypes.array.isRequired,
  }).isRequired,
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
  }).isRequired,
};

export default ViewDietPlan;
