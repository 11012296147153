import React from 'react';
import { Card, Col, Row } from 'antd';
import Paper from '@material-ui/core/Paper/Paper';
import NewIngredientForm from '../forms/NewIngredientForm';
import NewRecipeForm from '../forms/NewRecipeForm';

const DietCenterDashboard = () => (
  <div style={{ padding: 20 }}>
    <h1>Diet Center</h1>
    <Row gutter={20}>
      <Col span={6}>
        <Paper>
          <Card title="Add new ingredient">
            <NewIngredientForm />
          </Card>
        </Paper>
      </Col>
      <Col span={18}>
        <Paper>
          <Card title="Add new recipe">
            <NewRecipeForm />
          </Card>
        </Paper>
      </Col>
    </Row>
  </div>
);

export default DietCenterDashboard;
