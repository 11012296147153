import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { graphql } from 'react-apollo';
import { Form, Input, Button, Card, message } from 'antd';
import Paper from '@material-ui/core/Paper';
import * as compose from 'lodash/flowRight';
import { AuthConsumer } from '../auth/authContext';
import MUTATION from '../graphql/v2/mutations/loginMutation.graphql';

import './LoginPage.scss';

const FormItem = Form.Item;

class LoginPage extends Component {
  state = {
    submittingForm: false,
  };

  handleSubmit = (login) => {
    this.setState({
      submittingForm: true,
    });
    const { form, history, mutate } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        mutate({ variables: { email: values.email, password: values.password } })
          .then(({ data }) => {
            this.setState({
              submittingForm: false,
            });
            // Store auth data
            login(data.login);
            form.resetFields();
            history.push('/');
          })
          .catch((mutateErr) => {
            this.setState({
              submittingForm: false,
            });
            Sentry.captureException(mutateErr);
            message.error(mutateErr.message);
          });
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { submittingForm } = this.state;
    return (
      <AuthConsumer>
        {({ login }) => (
          <div id="body">
            <div id="loginFormBoxContainer">
              <Paper>
                <Card title="NutriToo Login" id="loginFormBox">
                  <Form
                    onSubmit={(event) => {
                      event.preventDefault();
                      this.handleSubmit(login);
                    }}
                    layout="vertical"
                  >
                    <FormItem hasFeedback label="Email">
                      {getFieldDecorator('email', {
                        rules: [
                          {
                            required: true,
                            message: 'Email is required!',
                          },
                          {
                            type: 'email',
                            message: 'Incorrect Email!',
                          },
                        ],
                      })(<Input type="email" required disabled={submittingForm} />)}
                    </FormItem>
                    <FormItem hasFeedback label="Password">
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: 'Password is required!',
                          },
                        ],
                      })(<Input type="password" required disabled={submittingForm} />)}
                    </FormItem>
                    <FormItem>
                      <Button type="primary" htmlType="submit" loading={submittingForm}>
                        Login
                      </Button>
                    </FormItem>
                  </Form>
                </Card>
              </Paper>
            </div>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

LoginPage.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default compose(graphql(MUTATION), Form.create())(LoginPage);
