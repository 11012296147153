import lodash from 'lodash';
import moment from 'moment';

export const CURRENT_VERSION = 'v1.8.2';

export const log = (data) => {
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV) console.log(data);
};

export const roleWeight = (role) => {
  if (role === 'admin') return 100;
  if (role === 'doctor') return 50;
  return 10;
};

export const daysOfWeek = {
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thur',
  5: 'fri',
  6: 'sat',
  7: 'sun',
};

export const medicalProblems = [
  { id: 1, title: 'Obesity' },
  { id: 2, title: 'Diabetes' },
  { id: 3, title: 'Anemia' },
  { id: 4, title: 'Hypertension (High Blood Pressure)' },
  { id: 5, title: 'Hypotension (Low Blood Pressure)' },
  { id: 6, title: 'Migraine' },
  { id: 7, title: 'Hyperthyroid' },
  { id: 8, title: 'Hypothyroid' },
  { id: 9, title: 'Acidity' },
  { id: 10, title: 'Polycystic Ovary Syndrome' },
  { id: 11, title: 'Kidney Stone' },
  { id: 12, title: 'Heart Conditions' },
  { id: 13, title: 'Hormonal Imbalance' },
  { id: 14, title: 'Osteoporosis' },
  { id: 15, title: 'Stress' },
  { id: 16, title: 'Insomnia' },
];

export const medicalProblemsObj = {
  1: 'Obesity',
  2: 'Diabetes',
  3: 'Anemia',
  4: 'Hypertension (High Blood Pressure)',
  5: 'Hypotension (Low Blood Pressure)',
  6: 'Migraine',
  7: 'Hyperthyroid',
  8: 'Hypothyroid',
  9: 'Acidity',
  10: 'Polycystic Ovary Syndrome',
  11: 'Kidney Stone',
  12: 'Heart Conditions',
  13: 'Hormonal Imbalance',
  14: 'Osteoporosis',
  15: 'Stress',
  16: 'Insomnia',
};

export const getCurrency = (value) =>
  value.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const units = [
  { key: 'tsp', name: 'teaspoon' },
  { key: 'tbsp', name: 'tablespoon' },
  { key: 'cup', name: 'cup' },
  { key: 'ml', name: 'milliliter' },
  { key: 'l', name: 'liter' },
  { key: 'mg', name: 'milligram' },
  { key: 'g', name: 'gram' },
  { key: 'kg', name: 'kilogram' },
];

export const subscriptionStatusDescription = {
  notStarted: 'Subscription not started',
  on: 'On',
  paused: 'Paused',
  ended: 'Completed',
  stopped: 'Stopped by doctor',
};

export const dietPlanStatusDescription = {
  created: 'Not created',
  yetToStart: 'Created, but not started',
  on: 'ON',
  ended: 'Ended',
};

export const appointmentStatusDescription = {
  open: 'Open',
  rescheduled: 'Rescheduled',
  fulfilled: 'Checked-out',
  noShow: 'No-show',
  cancelled: 'Cancelled',
};

export const dietPlanDefaultValues = (patient = {}, currentDietPlan = {}, lastDietPlan = {}) => {
  const initialData = {
    waterIntake: 3,
    generalGuidelines: [
      'Restrict your intake of tea and coffee.',
      'Avoid long gap between meals.',
      'Restrict fried foods, sweets, and preserved foods.',
      'Prefer tandoor, baked, grilled food stuff while eating out.',
      'No fruit juices at all and no fruit after second meal.',
      'Drink water half hour before meal.',
    ],
    specialGuidelines: [
      'No wheat, rice, South Indian, corns, sago (sabudana), banana, grapes, cheese, white bread, bakery products, red pumpkin, potato, red meat, monosodium glutamate (MSG) and junk food.',
      'Avoid taking table salt, pickle, papad and canned olive.',
      'Get adequate sleep each night',
      'Eat soluble fiber including fresh food / vegetables.',
      'Avoid chocolate, onion, pasta, bread, ice cream, corn, tomatoes, citrus fruit, dairy products, red wine, mono sodium glutamate (MSG), nutra sweet and eggs yolk.',
      'Limit take of vitamin K Reach food Brussels sprouts, spinach, broccoli, kale, banana, fish, coconut, goat cheese, custard apple, eggs yolk, parsley, prunes and cauliflower.',
      'Eat less Oxalate Food: - spinach, beet, almond, fig, okra, sweet potato, cashew, peanut, turmeric, sesame seeds and poppy seeds.',
      'Avoid having sapota, kiwi, banana, grapes, onion and cucumber, Soya, quinoa, wheat germ, cocoa and stevia sweetener.',
      "Avoid foods like soda, candy, ice creams, Dairy products, soy products, caffeine, alcohol, unhealthy fats such as saturated fats and Tran's fats.",
    ],
    entries: [
      {
        hours: 5,
        minutes: 30,
        mealType: 'Pre W/O',
        timeNote: '30-45 mins prior of w/o',
        meals: [],
        supplements: [],
      },
      {
        hours: 7,
        minutes: 0,
        mealType: 'Post W/O',
        timeNote: 'Within 1 hr',
        meals: [],
        supplements: [],
      },
      {
        hours: 9,
        minutes: 30,
        mealType: 'Breakfast',
        timeNote: null,
        meals: [],
        supplements: [],
      },
      {
        hours: 12,
        minutes: 30,
        mealType: 'Lunch',
        timeNote: null,
        meals: [],
        supplements: [],
      },
      {
        hours: 16,
        minutes: 0,
        mealType: 'Mid Meal',
        timeNote: null,
        meals: [],
        supplements: [],
      },
      {
        hours: 19,
        minutes: 30,
        mealType: 'Dinner',
        timeNote: null,
        meals: [],
        supplements: [],
      },
      {
        hours: 22,
        minutes: 0,
        mealType: null,
        timeNote: null,
        meals: [],
        supplements: [],
      },
    ],
    oilIntake: 10,
  };

  return lodash.assignWith(
    {},
    initialData,
    patient,
    lastDietPlan,
    currentDietPlan,
    (objValue, srcValue, key, object) => {
      if (key === 'startDate') {
        if (!srcValue) {
          const tomorrow = moment().add(1, 'days').startOf('day');
          if (!objValue) return tomorrow;
          let newStartDateMoment = moment(new Date(objValue))
            .add(object.defaultValidityDays + 1, 'days')
            .startOf('day');
          if (object.endDate)
            newStartDateMoment = moment(new Date(object.endDate)).add(1, 'days').startOf('day');
          if (tomorrow.isAfter(newStartDateMoment)) return tomorrow;
          return newStartDateMoment;
        }
        return moment(new Date(srcValue));
      }
      if (key === 'endDate') return srcValue;
      if (lodash.isNull(srcValue)) return objValue;
      if (Array.isArray(srcValue) && srcValue.length < 1) return objValue;
      if (lodash.isString(srcValue) && !srcValue) return objValue;
      return srcValue;
    },
  );
};

export const foodAllergies = [
  'Lactose (Milk)',
  'Food Additives',
  'Nuts',
  'Eggs',
  'Fish',
  'Wheat',
  'Peanuts',
  'Soy',
];

/** const to store the special guidelines for obesity */
export const specialGuidelinesForObesity = [
  'Obesity Guidelines',
  `Don't keep long gaps between meal.`,
  'Try and Have a early dinner.',
  'Avoid sugary Beverages processed food, fried food.',
  'Limit consumption of sweet, white bread & refined high calories, snacks, Alcohol.',
  'High fat dairy products.',
];

/** const to store the special guidelines for type 2 diabetes */
export const specialGuidelinesForType2Diabetes = [
  'Type-2 Diabetes Guidelines',
  'Sugary foods and beverages: Soda, fruit juices, candies, cakes, cookies, and other sweets should be limited or avoided.',
  'Refined carbohydrates: White bread, white rice, pasta, and other refined grains can cause rapid increases in blood sugar levels.',
  'High-fat foods: Limit consumption of fried foods, fatty cuts of meat, and full-fat dairy products, as they can contribute to weight gain and insulin resistance.',
  'Processed and packaged foods: These often contain added sugars, unhealthy fats, and high levels of sodium, which can negatively impact blood sugar control.',
  'Sweetened breakfast cereals: Opt for whole grain, low-sugar cereals or oatmeal instead.',
  'Fruits high in sugar: While fruits are generally healthy, those high in natural sugars such as bananas, grapes, and mangoes should be eaten in moderation.',
  'Alcohol: Drinking alcohol can cause fluctuations in blood sugar levels and should be consumed in moderation or avoided altogether.',
];

/** const to store the special guidelines for hypo thyroid */
export const specialGuidelinesForHypoThyroid = [
  'Hypo-Thyroid Guidelines',
  'Soy-based foods: Soy contains compounds called goitrogens, which can interfere with thyroid hormone production. Limit consumption of soybeans, soy milk, tofu and other soy based products.',
  'Cruciferous vegetables: Vegetables like broccoli, cabbage, cauliflower, Brussels sprouts, and kale also contain goitrogens.',
  'Eat in moderation-Gluten-containing foods: wheat barley and rye.',
  'Avoid processed foods.',
  'Excessive iodine: While iodine is essential for thyroid function, consuming too much can worsen hypothyroidism.',
  'Avoid excessive iodine supplementation and be cautious with iodine-rich foods like seaweed and iodized salt.',
  'High-fat dairy: Consider choosing low-fat or non-dairy alternatives like almond milk or coconut milk.',
  'Consume in moderately Caffeine and alcohol.',
];

/** const to store special guidelines for migraine */
export const specialGuidelinesForMigraine = [
  'Migraine Guidelines',
  'Tyramine-containing foods- It includes aged cheeses (such as blue cheese, cheddar, and parmesan), cured meats (like salami, pepperoni, and bacon), and fermented foods and soy sauce.',
  'MSG (Monosodium glutamate): MSG, a flavor enhancer - It is often found in Chinese food, canned soups, processed meats, and certain snack foods.',
  'Caffeine rich - coffee, tea, chocolate, and energy drinks.',
  'Alcohol: Certain types of alcohol, particularly red wine, beer, and champagne, contain substances like tyramine and sulfites that can trigger migraines in susceptible individuals.',
  'Aspartame and artificial sweeteners: Some people are sensitive to artificial sweeteners like aspartame, found in diet sodas, sugar-free gum, and some processed foods.',
  'Nitrites and nitrates: These preservatives are commonly found in processed meats like hot dogs, deli meats, and bacon and can trigger migraines.',
  'Histamine-containing foods: Foods high in histamine, such as aged cheeses, smoked fish, and fermented foods, may trigger migraines.',
  'Certain fruits: Banana.',
  'Skipping meals: Irregular meal timing or skipping meals altogether can trigger migraine. It is essential to maintain regular meal schedules and avoid prolonged periods of fasting.',
  'Dehydration: Stay hydrated by drinking plenty of water throughout the day.',
];

/** const to store special guidelines for hyper-acidity */
export const specialGuidelinesForHyperAcidity = [
  'Hyper-Acidity Guidelines',
  'Spicy foods: Spicy foods like chili peppers, hot sauces.',
  'Citrus fruits and juices: Oranges, lemons, grapefruits, and their juices are acidic and can increase stomach acidity.',
  'Tomato-based products: Tomatoes and tomato-based foods like sauces, salsa, and ketchup are acidic and can trigger reflux symptoms in some individuals.',
  'Carbonated beverages.',
  'Fried and fatty foods: High-fat foods like fried foods, fatty meats, and full-fat dairy products.',
  'Alcohol and caffeine and Chocolate contains caffeine and a compound called theobromine, both of which can contribute to acid reflux in some individuals.',
  'Large meals and late-night snacks: Eating large meals or consuming snacks close to bedtime can increase the risk of acid reflux. Aim to eat smaller, more frequent meals throughout the day and avoid lying down immediately after eating.',
  `Everyone's triggers can be different, so it's essential to pay attention to how your reacts Keeping a food diary can help identify individual triggers and patterns of symptoms.`,
];

export const invoiceStatus = (invoice) => {
  if (invoice.cancelled) return 'Cancelled';
  if (invoice.summary.balance === 0) return 'Paid';
  if (invoice.summary.totalPaid === 0) return 'Unpaid';
  return 'Partial Paid';
};
