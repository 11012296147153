import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { Query } from 'react-apollo';
import { Spin } from 'antd';

import subscriptionsDietPlansReportQuery from '../graphql/v2/queries/subscriptionsDietPlansReportQuery.graphql';

const SubscriptionsDpReport = ({ startDate, endDate }) => (
  <Query
    query={subscriptionsDietPlansReportQuery}
    variables={{
      startDate: moment(startDate).startOf('day'),
      endDate: moment(endDate).endOf('day'),
    }}
    fetchPolicy="network-only"
    onError={(qErr) => {
      Sentry.captureException(qErr);
    }}
    context={{ headers: { 'x-hasura-role': 'doctor' } }}
  >
    {({ loading, error, data }) => {
      if (loading) return <Spin />;
      if (error) return <p>ERROR: {error.message}</p>;
      const { subscriptionsDietPlansReport } = data;
      const stats = {
        totalSubscriptions: subscriptionsDietPlansReport.length,
        totalDp: 0,
        x1: 0,
        x4: 0,
        x10: 0,
        subNotStarted: 0,
        subOn: 0,
        subEnded: 0,
        subPaused: 0,
        subStopped: 0,
        dpCreated: 0,
        dpYetToStart: 0,
        dpEnded: 0,
        dpOn: 0,
      };
      subscriptionsDietPlansReport.forEach((entry) => {
        stats.totalDp += entry.dietPlansCount;
        if (entry.dietPlansCount === 1) stats.x1 += 1;
        if (entry.dietPlansCount === 4) stats.x4 += 1;
        if (entry.dietPlansCount === 10) stats.x10 += 1;
        if (entry.status === 'notStarted') {
          stats.subNotStarted += 1;
        }
        if (entry.status === 'on') {
          stats.subOn += 1;
        }
        if (entry.status === 'paused') {
          stats.subPaused += 1;
        }
        if (entry.status === 'ended') {
          stats.subEnded += 1;
        }
        if (entry.status === 'stopped') {
          stats.subStopped += 1;
        }
        entry.dietPlans.forEach((dp) => {
          if (dp.status === 'created') stats.dpCreated += 1;
          if (dp.status === 'yetToStart') stats.dpYetToStart += 1;
          if (dp.status === 'ended') stats.dpEnded += 1;
          if (dp.status === 'on') stats.dpOn += 1;
        });
      });

      return (
        <div>
          <h2 style={{ marginBottom: 25 }}>
            Subscriptions and Diet Plan report from{' '}
            <strong>{startDate.format('Do MMMM, YYYY')}</strong> to{' '}
            <strong>{endDate.format('Do MMMM, YYYY')}</strong>
          </h2>
          <h3>
            <strong>Subscriptions</strong>
          </h3>
          <table className="reportsStatsTable" style={{ marginBottom: 30 }}>
            <tbody>
              <tr>
                <td>Total Subscriptions</td>
                <th>{stats.totalSubscriptions}</th>
              </tr>
              <tr>
                <td>Diet Plan (x1) Subscriptions</td>
                <th>{stats.x1}</th>
              </tr>
              <tr>
                <td>Diet Plan (x4) Subscriptions</td>
                <th>{stats.x4}</th>
              </tr>
              <tr>
                <td>Diet Plan (x10) Subscriptions</td>
                <th>{stats.x10}</th>
              </tr>
              <tr>
                <td>Active Subscriptions</td>
                <th>{stats.subOn}</th>
              </tr>
              <tr>
                <td>Subscriptions Ended</td>
                <th>{stats.subEnded}</th>
              </tr>
              <tr>
                <td>Subscriptions Paused</td>
                <th>{stats.subPaused}</th>
              </tr>
              <tr>
                <td>Subscriptions Stopped</td>
                <th>{stats.subStopped}</th>
              </tr>
              <tr>
                <td>Subscriptions not started</td>
                <th>{stats.subNotStarted}</th>
              </tr>
            </tbody>
          </table>
          <h3>
            <strong>Diet Plans</strong>
          </h3>
          <table className="reportsStatsTable">
            <tbody>
              <tr>
                <td>Total Diet Plans</td>
                <th>{stats.totalDp}</th>
              </tr>
              <tr>
                <td>Active Diet Plans</td>
                <th>{stats.dpOn}</th>
              </tr>
              <tr>
                <td>Ended Diet Plans</td>
                <th>{stats.dpEnded}</th>
              </tr>
              <tr>
                <td>Created Diet Plans (yet to start)</td>
                <th>{stats.dpYetToStart}</th>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }}
  </Query>
);

SubscriptionsDpReport.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
};

export default SubscriptionsDpReport;
