import moment from 'moment';

/**
 * function to return the details of patients/clients who had recently visited clinic for the appointment
 * and has ongoing diet plan which had started between the last 6 to 15 days from today's date
 */
export const receptionistCallList = (patients) =>
  Array.isArray(patients) &&
  patients.length > 0 &&
  patients.filter((patient) => {
    if (
      Array.isArray(patient.appointments) &&
      patient.appointments.length > 0 &&
      moment(patient.appointments[0].start, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day')
    )
      return false;
    let currentActiveDietPlan = null;
    let pos = 0;
    const now = moment();
    if (
      Array.isArray(patient.subscriptions) &&
      patient.subscriptions.length > 0 &&
      Array.isArray(patient.subscriptions[0].dietPlans) &&
      patient.subscriptions[0].dietPlans.length > 0
    ) {
      patient.subscriptions[0].dietPlans.forEach((entry) => {
        if (entry.startDate && entry.subscriptionSequencePosition > pos) {
          const start = moment(new Date(entry.startDate));
          if (now.isAfter(start)) {
            if (entry.endDate) {
              const end = moment(new Date(entry.endDate));
              if (now.isBefore(end)) {
                currentActiveDietPlan = entry;
                pos = entry.subscriptionSequencePosition;
              }
            } else {
              currentActiveDietPlan = entry;
              pos = entry.subscriptionSequencePosition;
            }
          }
        }
      });
    }
    if (!currentActiveDietPlan) return false;
    const diffDays = moment().diff(moment(new Date(currentActiveDietPlan.startDate)), 'days');
    if (diffDays < 5 || diffDays >= 15) return false;
    return true;
  });

export default null;
