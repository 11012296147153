import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Query, Mutation } from 'react-apollo';
import { Spin, Button } from 'antd';
import { Switch, Route } from 'react-router-dom';
import Paper from '@material-ui/core/Paper/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { WarningRounded } from '@material-ui/icons';
import moment from 'moment';
import { medicalProblemsObj } from '../utils/globals';
import Link from '../components/Link';
import PatientRegistrationRatification from '../components/PatientRegistrationRatification';
import PatientAppointmentsTable from '../components/PatientAppointmentsTable';
import PatientSaralInvoicesComponent from '../components/PatientSaralInvoicesComponent';
import PatientSubscriptionsTable from '../components/PatientSubscriptionsTable';

import patientPageQuery from '../graphql/v2/queries/patientPageQuery.graphql';
import sendClinicReviewSMSMutation from '../graphql/v2/mutations/sendClinicReviewSMSMutation.graphql';

class PatientPage extends Component {
  state = {};

  render() {
    const { match, location, history, patientId: patientKey } = this.props;

    const patientId = patientKey || (match && parseInt(match.params.id, 10));

    let secNavValue = false;
    if (location && location.pathname.includes('appointments')) secNavValue = 0;
    if (location && location.pathname.includes('payments')) secNavValue = 1;
    if (location && location.pathname.includes('subscriptions')) secNavValue = 2;

    return (
      <Query
        variables={{ patientId }}
        query={patientPageQuery}
        fetchPolicy="network-only"
        onError={(qErr) => {
          Sentry.captureException(qErr);
        }}
        context={{ headers: { 'x-hasura-role': 'receptionist' } }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <Spin size="large" />;
          if (error) return <p>ERROR: {error.message}</p>;

          const { patient } = data;
          return (
            <div style={{ padding: 20 }}>
              <h1>Patient Details</h1>
              <Paper>
                <div style={{ padding: 20 }}>
                  <h2 style={{ fontWeight: 700 }}>{patient.name}</h2>
                  <Link to={`/receptionist/patient/${patient.id}/basicDetails/edit`}>
                    <Button
                      htmlType="button"
                      type="primary"
                      style={{ marginBottom: 15, marginRight: 10 }}
                    >
                      Edit Basic Details
                    </Button>
                  </Link>
                  <Link to={`/receptionist/registration/${patient.id}`}>
                    <Button htmlType="button" type="primary" style={{ marginBottom: 15 }}>
                      Edit Registration Details
                    </Button>
                  </Link>
                  {!patient.registrationFormCompleted ? (
                    <p
                      style={{
                        color: 'red',
                        fontStyle: 'italic',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '1rem',
                      }}
                    >
                      <WarningRounded style={{ marginRight: 5, fontSize: '1.6rem' }} /> Registration
                      form not completed
                    </p>
                  ) : null}
                  <table id="patientsPageInfoTable">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Mobile: </strong>+91 {patient.mobile}
                        </td>
                        <td>
                          <strong>Email: </strong>
                          {patient.email}
                        </td>
                        <td>
                          <strong>Address: </strong>
                          {patient.address}
                          {patient.landmark ? `, ${patient.landmark}` : null}
                          {patient.city ? `, ${patient.city}` : null}
                          {patient.pincode ? `, ${patient.pincode}` : null}
                        </td>
                        <td>
                          <strong>DOB: </strong>
                          {patient.dob
                            ? moment(patient.dob, 'YYYY-MM-DD').format('Do MMM, YYYY')
                            : null}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Age: </strong>
                          {patient.age ? `${patient.age} yrs` : null}
                        </td>

                        <td>
                          <strong>Sex: </strong>
                          {patient.sex}
                        </td>
                        <td>
                          <strong>Height: </strong>
                          {patient.height ? `${patient.height} cms` : null}
                        </td>
                        <td>
                          <strong>Blood Group: </strong>
                          {patient.bloodGroup}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Anniversary: </strong>
                          {patient.anniversaryDate
                            ? moment(patient.anniversaryDate, 'YYYY-MM-DD').format('Do MMM')
                            : null}
                        </td>
                        <td>
                          <strong>Alternate contact no.: </strong>
                          {patient.alternateContactNo}
                        </td>
                        <td>
                          <strong>Communication channels: </strong>
                          {Array.isArray(patient.communicationChannelPreference)
                            ? patient.communicationChannelPreference.join(', ')
                            : null}
                        </td>
                        <td>
                          <strong>Referred By: </strong>
                          {patient.referenceMode}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Fitness Goals: </strong>
                          {Array.isArray(patient.fitnessGoals)
                            ? patient.fitnessGoals.join(', ')
                            : null}
                        </td>
                        <td>
                          <strong>Diet Type: </strong>
                          {patient.dietType}
                        </td>
                        <td>
                          <strong>Exercise: </strong>
                          {patient.exercise ? 'Yes' : 'No'}
                        </td>
                        <td>
                          <strong>Exercise Level: </strong>
                          {patient.exerciseLevel}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Smoking: </strong>
                          {patient.smoking ? 'Yes' : 'No'}
                        </td>
                        <td>
                          <strong>Smoking Frequency: </strong>
                          {patient.smokingFrequency}
                        </td>
                        <td>
                          <strong>Alcohol: </strong>
                          {patient.alcoholConsumption ? 'Yes' : 'No'}
                        </td>
                        <td>
                          <strong>Alcohol Consumption Frequency: </strong>
                          {patient.alcoholConsumptionFrequency}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Cooking Oil: </strong>
                          {patient.cookingOil}
                        </td>
                        <td>
                          <strong>Food Allergies: </strong>
                          {Array.isArray(patient.foodAllergies)
                            ? patient.foodAllergies.join(', ')
                            : null}
                        </td>
                        <td>
                          <strong>Medical Problems:</strong>
                          {Array.isArray(patient.medicalProblems) &&
                          patient.medicalProblems.length !== 0
                            ? patient.medicalProblems.map((entry, index) => {
                                if (index === 0) return ` ${medicalProblemsObj[entry]}`;
                                return `, ${medicalProblemsObj[entry]}`;
                              })
                            : ' None'}
                        </td>
                        <td>
                          <strong>Injuries in past two years: </strong>
                          {patient.pastTwoYearInjuries}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Medication Intake Schedule: </strong>
                          {patient.medicationIntakeSchedule}
                        </td>
                        <td>
                          <strong>Injuries in past two years: </strong>
                          {patient.pastTwoYearInjuries}
                        </td>
                        <td>
                          <strong>Food supplements intake: </strong>
                          {patient.foodSupplimentsIntakeSchedule}
                        </td>
                        <td>
                          <strong>Language Preference: </strong>
                          {patient.languagePreference}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Aadhaar: </strong>
                          {patient.aadhaar}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div style={{ paddingTop: 15, marginTop: 15, borderTop: '1px solid #DDDDDD' }}>
                    <h3 style={{ fontWeight: 700 }}>Clinic Review SMS</h3>
                    <p>
                      <strong>Total SMS sent: </strong>
                      {patient.reviewSmsSentCount}
                    </p>
                    <p>
                      <strong>Last SMS was sent on: </strong>
                      {patient.lastReviewSmsSentAt
                        ? moment(new Date(patient.lastReviewSmsSentAt)).format('Do MMMM YYYY')
                        : 'No SMS sent!'}
                    </p>
                    <Mutation
                      mutation={sendClinicReviewSMSMutation}
                      variables={{ patientId: patient.id }}
                      onError={(mutErr) => {
                        Sentry.captureException(mutErr);
                      }}
                      context={{ headers: { 'x-hasura-role': 'receptionist' } }}
                      refetchQueries={['patientPage']}
                    >
                      {(sendClinicReviewSMS, { loading: mutLoading }) => (
                        <Button
                          type="primary"
                          loading={mutLoading}
                          style={{ marginRight: 10 }}
                          onClick={sendClinicReviewSMS}
                        >
                          Send review SMS
                        </Button>
                      )}
                    </Mutation>
                  </div>

                  <div style={{ paddingTop: 15, marginTop: 15, borderTop: '1px solid #DDDDDD' }}>
                    <h3 style={{ fontWeight: 700 }}>Registration Ratification</h3>
                    {patient.registrationRatified ? 'Completed!' : null}
                    <PatientRegistrationRatification
                      otpGenerated={!!patient.ratificationOtpGeneratedAt}
                      registrationRatified={patient.registrationRatified}
                      patientId={patient.id}
                      refetchDataOnMutation={refetch}
                    />
                  </div>
                </div>

                <Tabs
                  value={secNavValue}
                  onChange={(event, value) => {
                    if (value === 0) {
                      history.push(`${match && match.url}/appointments`);
                    }
                    if (value === 1) history.push(`${match && match.url}/invoices`);
                    if (value === 2) history.push(`${match && match.url}/subscriptions`);
                  }}
                  style={{ backgroundColor: '#0029b1' }}
                >
                  <Tab label="Appointments" style={{ color: '#FFFFFF' }} />
                  <Tab label="Invoices" style={{ color: '#FFFFFF' }} />
                  <Tab label="Subscriptions" style={{ color: '#FFFFFF' }} />
                </Tabs>

                <div style={{ padding: 20 }}>
                  <Switch>
                    <Route
                      path={`${match && match.path}/appointments`}
                      render={(routeProps) => (
                        <PatientAppointmentsTable patientId={patient.id} {...routeProps} />
                      )}
                    />
                    <Route
                      path={`${match && match.path}/invoices`}
                      render={(routeProps) => (
                        <PatientSaralInvoicesComponent patientId={patient.id} {...routeProps} />
                      )}
                    />
                    <Route
                      path={`${match && match.path}/subscriptions`}
                      render={(routeProps) => (
                        <PatientSubscriptionsTable patientId={patient.id} {...routeProps} />
                      )}
                    />
                  </Switch>
                </div>
              </Paper>
            </div>
          );
        }}
      </Query>
    );
  }
}

PatientPage.propTypes = {
  patientId: PropTypes.number.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default PatientPage;
