/* eslint-disable spaced-comment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Menu } from 'antd';
import ViewOnlyIcon from '@material-ui/icons/Visibility';
import NodeAddIcon from '@material-ui/icons/NoteAdd';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LockIcon from '@material-ui/icons/Lock';
import EditIcon from '@material-ui/icons/Edit';

import './ActiveSubscription.scss';

import {
  subscriptionStatusDescription,
  dietPlanStatusDescription,
  dietPlanDefaultValues,
} from '../utils/globals';
import DietPlanForm from '../forms/DietPlanForm';
import UpdateSmsServiceComponent from './UpdateSmsServiceComponent';

const dpStateIcon = {
  create: <NodeAddIcon style={{ marginRight: 5 }} />,
  edit: <EditIcon style={{ marginRight: 5 }} />,
  active: <CheckCircleIcon style={{ marginRight: 5 }} />,
  viewOnly: <ViewOnlyIcon style={{ marginRight: 5 }} />,
  disabled: <LockIcon style={{ marginRight: 5 }} />,
};

const dpModeFromStatus = {
  created: 'create',
  yetToStart: 'view',
  on: 'view',
  ended: 'print',
};

class ActiveSubscription extends Component {
  state = {
    selectedSubscriptionPlanPosition: null,
    nowMoment: moment(),
  };

  handleMenuClick = (e) => {
    this.setState({
      selectedSubscriptionPlanPosition: parseInt(e.key, 10),
    });
  };

  calculateDietPlanDays = (startDate, endDate) => {
    const { nowMoment } = this.state;
    if (startDate) {
      const startMoment = moment(new Date(startDate));
      if (endDate) {
        return moment(new Date(endDate)).diff(startMoment, 'days');
      }
      return nowMoment.diff(startMoment, 'days');
    }
    return '-';
  };

  render() {
    const { subscription, appointmentId, refetchQuery } = this.props;
    if (!subscription) return <p>No Active Subscription</p>;

    const { dietPlans, patient } = subscription;
    const { selectedSubscriptionPlanPosition, nowMoment } = this.state;
    const selectedDietPlanIndex = selectedSubscriptionPlanPosition - 1;
    const selectedDietPlan = dietPlans[selectedDietPlanIndex];

    const startDateMoment = moment(new Date(subscription.startDate));

    const dpStates = new Array(dietPlans.length);
    for (let i = 0; i < dietPlans.length; i++) {
      const dp = dietPlans[i];
      const { status } = dp;
      if (i === 0) {
        if (status === 'created') dpStates[i] = 'create';
        else if (status === 'yetToStart') dpStates[i] = 'edit';
        else if (status === 'on') dpStates[i] = 'active';
        else if (status === 'ended') dpStates[i] = 'viewOnly';
      } else {
        const lastDpState = dpStates[i - 1];
        if (lastDpState === 'create' || lastDpState === 'edit') dpStates[i] = 'disabled';
        else if (lastDpState === 'disabled') dpStates[i] = 'disabled';
        else if (lastDpState === 'active') dpStates[i] = 'create';
        else if (status === 'created') dpStates[i] = 'create';
        else if (status === 'yetToStart') dpStates[i] = 'edit';
        else if (status === 'on') dpStates[i] = 'active';
        else if (status === 'ended') dpStates[i] = 'viewOnly';
      }
    }

    return (
      <div>
        {/*<Button htmlType="button" type="primary" style={{ margin: '15px 0' }}>*/}
        {/*Stop Subscription*/}
        {/*</Button>*/}
        <p>
          <strong>Subscription start date: </strong>
          {startDateMoment.format('Do MMM, YYYY')}
        </p>
        <p>
          <strong>Total diet plans in subscription: </strong>
          {subscription.dietPlansCount}
        </p>
        <p>
          <strong>Status: </strong>
          {subscriptionStatusDescription[subscription.status]}
        </p>
        {subscription.statusReason ? (
          <p>
            <strong>Status remark: </strong>
            {subscription.statusReason}
          </p>
        ) : null}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginRight: 20 }}>
            <strong>Diet Plan SMS service: </strong>
            {subscription.sendDietPlanSms ? 'ON' : 'OFF'}
          </p>
          {subscription.status === 'on' ? (
            <UpdateSmsServiceComponent subscription={subscription} />
          ) : null}
        </div>
        <p>
          <strong>Subscription active for: </strong>
          {Math.round(moment.duration(nowMoment.diff(startDateMoment)).asDays())} days
        </p>
        <table id="activeSubscriptionDietPlanSummaryTable">
          <thead>
            <tr>
              <th />
              {dietPlans.map((entry) => (
                <th key={entry.id}>
                  DP-
                  {entry.subscriptionSequencePosition}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Status</th>
              {dietPlans.map((entry) => (
                <td key={entry.id}>{dietPlanStatusDescription[entry.status]}</td>
              ))}
            </tr>
            <tr>
              <th>Start Date</th>
              {dietPlans.map((entry) => (
                <td key={entry.id}>
                  {entry.startDate ? moment(new Date(entry.startDate)).format('Do MMM, YYYY') : '-'}
                </td>
              ))}
            </tr>
            <tr>
              <th>End Date</th>
              {dietPlans.map((entry) => (
                <td key={entry.id}>
                  {entry.endDate ? moment(new Date(entry.endDate)).format('Do MMM, YYYY') : '-'}
                </td>
              ))}
            </tr>
            <tr>
              <th>Plan Validity (Days)</th>
              {dietPlans.map((entry) => (
                <td key={entry.id}>{entry.defaultValidityDays}</td>
              ))}
            </tr>
            <tr>
              <th>Plan On For (Days)</th>
              {dietPlans.map((entry) => (
                <td key={entry.id}>{this.calculateDietPlanDays(entry.startDate, entry.endDate)}</td>
              ))}
            </tr>
          </tbody>
        </table>

        <Menu
          onClick={this.handleMenuClick}
          selectedKeys={[`${selectedSubscriptionPlanPosition}`]}
          mode="horizontal"
        >
          {dietPlans.map((entry, index) => (
            <Menu.Item
              key={entry.subscriptionSequencePosition}
              disabled={dpStates[index] === 'disabled'}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {dpStateIcon[dpStates[index]]}
                DP - {entry.subscriptionSequencePosition}
              </div>
            </Menu.Item>
          ))}
        </Menu>

        {selectedDietPlan ? (
          <div style={{ marginTop: 20 }}>
            <DietPlanForm
              id={selectedDietPlan.id}
              defaultValues={dietPlanDefaultValues(
                patient,
                selectedDietPlan,
                selectedDietPlanIndex > 0 ? dietPlans[selectedDietPlanIndex - 1] : {},
              )}
              isLastDietPlan={selectedDietPlan.isLastPlanOfSubscription}
              defaultValidityDays={selectedDietPlan.defaultValidityDays}
              mode={dpModeFromStatus[selectedDietPlan.status]}
              prevDietPlanId={
                selectedDietPlanIndex > 0 ? dietPlans[selectedDietPlanIndex - 1].id : null
              }
              appointmentId={appointmentId}
              refetchQuery={refetchQuery}
              patientId={patient ? patient.id : 0}
              planStatus={selectedDietPlan.status}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

ActiveSubscription.propTypes = {
  subscription: PropTypes.shape({
    id: PropTypes.number.isRequired,
    dietPlans: PropTypes.array.isRequired,
    startDate: PropTypes.string.isRequired,
    dietPlansCount: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    statusReason: PropTypes.string.isRequired,
    sendDietPlanSms: PropTypes.bool.isRequired,
    patient: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  appointmentId: PropTypes.number,
  refetchQuery: PropTypes.func.isRequired,
};

ActiveSubscription.defaultProps = {
  appointmentId: null,
};

export default ActiveSubscription;
