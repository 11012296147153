import React, { useState } from 'react';
import { Select } from 'antd';
import Paper from '@material-ui/core/Paper';
import SettingsComponent from '../components/SettingsComponent';

const { Option } = Select;

const SettingsPage = () => {
  const [setting, setSetting] = useState(undefined);

  return (
    <div style={{ padding: 20 }}>
      <div className="heading">
        <h1>Settings</h1>
      </div>
      <Paper style={{ padding: 20 }}>
        <div>
          <h3>Select settings</h3>
          <Select
            showSearch
            style={{ width: 400 }}
            placeholder="Select settings"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => setSetting(value)}
            value={setting}
          >
            <Option value="meals">Meals</Option>
            <Option value="supplements">Supplements</Option>
          </Select>
        </div>
      </Paper>

      {setting !== undefined && (
        <SettingsComponent
          key={setting}
          settingType={setting}
          resetSettings={() => setSetting(undefined)}
        />
      )}
    </div>
  );
};

export default SettingsPage;
