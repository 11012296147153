import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { DatePicker, message, Spin, Table, Input, Button, Icon } from 'antd';
import { useQuery } from 'react-apollo';
import PropTypes from 'prop-types';
import moment from 'moment';

import newPatientsReportQuery from '../graphql/v2/queries/newPatientsReportQuery.graphql';

const { Column } = Table;

const handleSearch = (selectedKeys, confirm) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters();
};

const FilterDropdownComponent = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  name,
}) => (
  <div style={{ padding: 8 }}>
    <Input
      placeholder={`Search by ${name}`}
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => handleSearch(selectedKeys, confirm)}
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
    <Button
      type="button"
      onClick={() => handleSearch(selectedKeys, confirm)}
      icon="search"
      size="small"
      style={{ width: 90, marginRight: 8 }}
    >
      Search
    </Button>
    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
      Reset
    </Button>
  </div>
);

const getColumnSearchProps = (dataIndex, name) => ({
  filterDropdown: (props) => <FilterDropdownComponent name={name} {...props} />,
  filterIcon: (filtered) => (
    <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
});

const PatientsReportComponent = (props) => {
  const { startDate, endDate } = props;

  const { loading, error, data } = useQuery(newPatientsReportQuery, {
    variables: {
      startDate: moment(startDate).startOf('day'),
      endDate: moment(endDate).endOf('day'),
    },
    context: { headers: { 'x-hasura-role': 'receptionist' } },
  });

  if (loading) return <Spin />;
  if (error) return `Error ${error.message}`;

  const { newPatientsReport } = data;
  return (
    <Paper style={{ marginTop: 20, padding: 20 }}>
      <Table
        loading={loading}
        dataSource={newPatientsReport}
        rowKey="id"
        bordered
        pagination={false}
      >
        <Column
          title="Date Added"
          key="createdAt"
          render={(text, record) => moment(record.createdAt).format('Do MMM, YYYY')}
        />
        <Column
          title="Name"
          key="name"
          render={(text, record) => record.name}
          {...getColumnSearchProps('name', 'name')}
        />
        <Column title="Mobile" key="mobile" render={(text, record) => record.mobile} />
        <Column title="Email" key="email" render={(text, record) => record.email} />
      </Table>
    </Paper>
  );
};

const ReceptionistReportsPage = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const startDateOnChange = (date) => {
    if (endDate && date.isAfter(endDate)) {
      message.error('Start date cannot be after end date');
    } else {
      setStartDate(date);
    }
  };

  const endDateOnChange = (date) => {
    if (startDate && date.isBefore(startDate)) {
      message.error('End date cannot be before start date');
    } else {
      setEndDate(date);
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <Paper style={{ padding: 20 }} className="hideInPrint">
        <h1>New Patients Report</h1>
        <div style={{ display: 'flex' }}>
          <div>
            <p>Start Date:</p>
            <DatePicker
              onChange={startDateOnChange}
              value={startDate}
              placeholder="Start Date"
              format="Do MMM, YYYY"
            />
          </div>
          <div style={{ marginLeft: 15 }}>
            <p>End Date:</p>
            <DatePicker
              onChange={endDateOnChange}
              value={endDate}
              placeholder="End Date"
              format="Do MMM, YYYY"
            />
          </div>
        </div>
      </Paper>
      {startDate !== null && endDate !== null ? (
        <PatientsReportComponent startDate={startDate} endDate={endDate} />
      ) : null}
    </div>
  );
};

PatientsReportComponent.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
};

FilterDropdownComponent.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  confirm: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default ReceptionistReportsPage;
