import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Spin, Form, Row, Col, Select, Button, message } from 'antd';
import { Query, Mutation } from 'react-apollo';
import DoctorPatientCheckoutForm from '../forms/DoctorPatientCheckoutForm';
import ActiveSubscription from '../components/ActiveSubscription';

import { medicalProblemsObj, foodAllergies, dietPlanDefaultValues } from '../utils/globals';

import QUERY from '../graphql/v2/queries/patientVisitQuery.graphql';
import UpdatePatientFoodAllergiesMutation from '../graphql/v2/mutations/updatePatientFoodAllergiesMutation.graphql';
import DietPlanForm from '../forms/DietPlanForm';

const FormItem = Form.Item;
const { Option } = Select;

class DoctorPatientVisit extends Component {
  state = {
    savingFoodAllergies: false,
  };

  render() {
    const { savingFoodAllergies } = this.state;

    const { appointmentId, form } = this.props;

    const { getFieldDecorator, validateFieldsAndScroll } = form;
    return (
      <Query
        query={QUERY}
        variables={{ appointmentId }}
        fetchPolicy="network-only"
        onError={(qErr) => {
          Sentry.captureException(qErr);
        }}
        context={{ headers: { 'x-hasura-role': 'doctor' } }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <Spin />;
          if (error) return <p>{error.message}</p>;

          const { appointment } = data;
          const { patient } = appointment;
          const { lastAppointment, activeSubscription } = patient;

          // stores the value of active subscription data
          const activeSubscriptionData =
            Array.isArray(activeSubscription) && activeSubscription.length > 0
              ? activeSubscription[0]
              : undefined;

          return (
            <div>
              <h2
                style={{
                  borderBottom: '1px solid #999',
                  marginBottom: 15,
                }}
                className="hideInPrint"
              >
                Patient Details
              </h2>
              <Row gutter={20} className="hideInPrint">
                <Col span={6}>
                  <p>
                    <strong>Name:</strong> {patient.name}
                  </p>
                </Col>
                <Col span={6}>
                  <p>
                    <strong>Mobile:</strong> {patient.mobile}
                  </p>
                </Col>
                <Col span={6}>
                  <p>
                    <strong>Email:</strong>{' '}
                    {Object.prototype.hasOwnProperty.call(patient, 'email') && patient.email
                      ? patient.email
                      : ''}
                  </p>
                </Col>
                {Object.prototype.hasOwnProperty.call(patient, 'age') && patient.age ? (
                  <Col span={6}>
                    <p>
                      <strong>Age:</strong> {patient.age} yrs
                    </p>
                  </Col>
                ) : null}
                {Object.prototype.hasOwnProperty.call(patient, 'height') && patient.height ? (
                  <Col span={6}>
                    <p>
                      <strong>Height:</strong> {patient.height} cms
                    </p>
                  </Col>
                ) : null}
                {Object.prototype.hasOwnProperty.call(patient, 'bloodGroup') &&
                patient.bloodGroup ? (
                  <Col span={6}>
                    <p>
                      <strong>Blood Group:</strong> {patient.bloodGroup}
                    </p>
                  </Col>
                ) : null}
                {Object.prototype.hasOwnProperty.call(patient, 'fitnessGoals') &&
                Array.isArray(patient.fitnessGoals) &&
                patient.fitnessGoals.length > 0 ? (
                  <Col span={6}>
                    <p>
                      <strong>Fitness Goals:</strong>
                      {patient.fitnessGoals.map((goal, index) => {
                        if (index === 0) return ` ${goal}`;
                        return `, ${goal}`;
                      })}
                    </p>
                  </Col>
                ) : null}
                {Object.prototype.hasOwnProperty.call(patient, 'dietType') && patient.dietType ? (
                  <Col span={6}>
                    <p>
                      <strong>Diet Preference:</strong> {patient.dietType}
                    </p>
                  </Col>
                ) : null}
                {Object.prototype.hasOwnProperty.call(patient, 'exercise') && patient.exercise ? (
                  <Col span={6}>
                    <p>
                      <strong>Exercise:</strong> {patient.exercise ? patient.exerciseLevel : 'No'}
                    </p>
                  </Col>
                ) : null}
                {Object.prototype.hasOwnProperty.call(patient, 'smoking') && patient.smoking ? (
                  <Col span={6}>
                    <p>
                      <strong>Smoking:</strong> {patient.smoking ? patient.smokingFrequency : 'No'}
                    </p>
                  </Col>
                ) : null}
                {Object.prototype.hasOwnProperty.call(patient, 'alcoholConsumption') &&
                patient.alcoholConsumption ? (
                  <Col span={6}>
                    <p>
                      <strong>Alcohol:</strong>{' '}
                      {patient.alcoholConsumption ? patient.alcoholConsumptionFrequency : 'No'}
                    </p>
                  </Col>
                ) : null}
                {Object.prototype.hasOwnProperty.call(patient, 'medicalProblems') &&
                Array.isArray(patient.medicalProblems) &&
                patient.medicalProblems.length > 0 ? (
                  <Col span={6}>
                    <p>
                      <strong>Medical Problems:</strong>
                      {patient.medicalProblems.map((entry, index) => {
                        if (index === 0) return ` ${medicalProblemsObj[entry]}`;
                        return `, ${medicalProblemsObj[entry]}`;
                      })}
                    </p>
                  </Col>
                ) : (
                  <Col span={6}>
                    <p>
                      <strong>Medical Problems:</strong>
                      <span> None</span>
                    </p>
                  </Col>
                )}
              </Row>

              {!appointment.doctorCheckedOut ? (
                <Row className="hideInPrint" style={{ marginTop: 20 }}>
                  <Col span={24}>
                    <h2
                      style={{
                        borderBottom: '1px solid #999',
                        marginBottom: 15,
                      }}
                    >
                      Appointment
                    </h2>
                    {lastAppointment && lastAppointment.visitNotes ? (
                      <div>
                        <p>
                          <strong>NOTES FROM LAST APPOINTMENT:</strong>
                        </p>
                        <p>{lastAppointment.visitNotes}</p>
                      </div>
                    ) : null}
                    <DoctorPatientCheckoutForm
                      refetchQuery={refetch}
                      appointment={appointment}
                      hasActiveSubscription={
                        !!(
                          activeSubscriptionData &&
                          (activeSubscriptionData.status === 'on' ||
                            activeSubscriptionData.status === 'paused')
                        )
                      }
                    />
                  </Col>
                </Row>
              ) : null}

              <Row style={{ marginTop: 20 }}>
                <Col span={24}>
                  <Mutation mutation={UpdatePatientFoodAllergiesMutation}>
                    {(updatePatientFoodAllergies) => (
                      <Form
                        layout="inline"
                        onSubmit={(e) => {
                          e.preventDefault();
                          this.setState({
                            savingFoodAllergies: true,
                          });

                          validateFieldsAndScroll((err, values) => {
                            if (!err) {
                              updatePatientFoodAllergies({
                                variables: {
                                  foodAllergies: `{${values.foodAllergies.toString()}}`,
                                  patientId: patient.id,
                                },
                                context: { headers: { 'x-hasura-role': 'doctor' } },
                              })
                                .then(() => {
                                  this.setState({
                                    savingFoodAllergies: false,
                                  });
                                  message.success('Patient allergies updated');
                                })
                                .catch((mutateErr) => {
                                  console.log(mutateErr);
                                  this.setState({
                                    savingFoodAllergies: false,
                                  });
                                  message.error(mutateErr.message);
                                  Sentry.captureException(mutateErr);
                                });
                            } else {
                              this.setState({
                                savingFoodAllergies: false,
                              });
                            }
                          });
                        }}
                      >
                        <FormItem label="Food Allergies">
                          {getFieldDecorator('foodAllergies', {
                            initialValue: Array.isArray(patient.foodAllergies)
                              ? patient.foodAllergies
                              : [],
                          })(
                            <Select
                              mode="tags"
                              style={{ width: 600 }}
                              disabled={savingFoodAllergies}
                            >
                              {foodAllergies.map((entry) => (
                                <Option key={entry} value={entry}>
                                  {entry}
                                </Option>
                              ))}
                            </Select>,
                          )}
                        </FormItem>
                        <FormItem>
                          <Button type="primary" htmlType="submit" loading={savingFoodAllergies}>
                            Update patient&apos;s allergies
                          </Button>
                        </FormItem>
                      </Form>
                    )}
                  </Mutation>
                </Col>
              </Row>

              {activeSubscriptionData ? (
                <Row style={{ marginTop: 20 }}>
                  <Col span={24}>
                    <h2
                      style={{
                        borderBottom: '1px solid #999',
                        marginBottom: 15,
                      }}
                    >
                      Active Subscription
                    </h2>
                    <ActiveSubscription
                      subscription={activeSubscriptionData}
                      appointmentId={appointment.id}
                      refetchQuery={refetch}
                    />
                  </Col>
                </Row>
              ) : (
                <Row style={{ marginTop: 20 }}>
                  <Col span={24}>
                    <h2
                      style={{
                        borderBottom: '1px solid #999',
                        marginBottom: 15,
                      }}
                    >
                      Temporary Diet Plan
                    </h2>
                    <DietPlanForm
                      appointmentId={appointment.id}
                      patientId={patient.id}
                      mode="local"
                      defaultValues={dietPlanDefaultValues(patient)}
                    />
                  </Col>
                </Row>
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

DoctorPatientVisit.propTypes = {
  appointmentId: PropTypes.number.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
};

export default Form.create()(DoctorPatientVisit);
