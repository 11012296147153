import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { Spin } from 'antd';
import moment from 'moment';
import { Refresh } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import Link from './Link';

import QUERY from '../graphql/v2/queries/doctorAppointmentsTodayQuery.graphql';

import './TodayAppointmentList.scss';

const styles = {
  head: {
    padding: 10,
    textAlign: 'center',
    fontWeight: 700,
  },
  noApptmnt: {
    padding: 10,
    textAlign: 'center',
  },
};

const TodayAppointmentList = (props) => {
  const { selectedAppointment, data } = props;
  const { loading, refetch } = data;
  if (loading) return <Spin />;
  const { appointments } = data;
  if (Array.isArray(appointments) && appointments.length > 0) {
    return (
      <div>
        <h3 style={styles.head}>
          Today&apos;s appointments
          <IconButton
            color="primary"
            aria-label="Refresh"
            onClick={() => {
              refetch();
            }}
          >
            <Refresh />
          </IconButton>
        </h3>
        {appointments.map((entry) => (
          <Link to={`/doctor/appointment/${entry.id}`} key={entry.id}>
            <div
              className={
                entry.id === selectedAppointment
                  ? 'taMenuItem taMenuItemSelected'
                  : 'taMenuItem taMenuItemNotSelected'
              }
            >
              <div>
                {moment(new Date(entry.start)).format('hh:mm A')} -{' '}
                {moment(new Date(entry.end)).format('hh:mm A')}
              </div>
              <div>{entry.patient.name}</div>
            </div>
          </Link>
        ))}
      </div>
    );
  }
  return (
    <div>
      <h3 style={styles.head}>
        Today&apos;s appointments
        <IconButton
          color="primary"
          aria-label="Refresh"
          onClick={() => {
            refetch();
          }}
        >
          <Refresh />
        </IconButton>
      </h3>
      <p style={styles.noApptmnt}>No appointments today!</p>
    </div>
  );
};

TodayAppointmentList.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    refetch: PropTypes.func.isRequired,
    appointments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    ),
    error: PropTypes.any,
  }).isRequired,
  selectedAppointment: PropTypes.number,
};

TodayAppointmentList.defaultProps = {
  selectedAppointment: null,
};

export default graphql(QUERY, {
  options: {
    fetchPolicy: 'network-only',
    variables: { startDate: moment().startOf('day'), endDate: moment().endOf('day') },
    context: { headers: { 'x-hasura-role': 'doctor' } },
  },
})(TodayAppointmentList);
