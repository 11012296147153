import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Redirect } from 'react-router-dom';
import { Query, Mutation } from 'react-apollo';
import { Spin, message, Button as AntBtn, Form, Input } from 'antd';
import Paper from '@material-ui/core/Paper';
import { getCurrency } from '../utils/globals';
import QUERY from '../graphql/v2/queries/receptionistAppointmentCheckoutQuery.graphql';
import REVISE_MUTATION from '../graphql/v2/mutations/appointmentReviseMutation.graphql';
import APPT_FULFILL_MUTATION from '../graphql/v2/mutations/fulfillAppointmentMutation.graphql';

const FormItem = Form.Item;

class PatientReceptionistCheckout extends Component {
  render() {
    const { history, match, form } = this.props;
    const { getFieldDecorator, validateFieldsAndScroll, resetFields } = form;
    return (
      <Query
        query={QUERY}
        fetchPolicy="network-only"
        variables={{ appointmentId: match.params.appointmentId }}
        onError={(qErr) => {
          Sentry.captureException(qErr);
        }}
        context={{ headers: { 'x-hasura-role': 'receptionist' } }}
      >
        {({ loading, error, data }) => {
          if (loading) return <Spin size="large" />;
          if (error) return `Error! ${error.message}`;

          const { appointment } = data;
          if (!appointment.doctorCheckedOut) {
            message.error('Doctor has not checked out this patient');
            return <Redirect to={match.url.includes('doctor') ? '/doctor' : '/receptionist'} />;
          }

          const { services, id } = appointment;

          let totalBeforeDiscount = 0;
          let discount = 0;
          if (services) {
            services.services.forEach((entry) => {
              totalBeforeDiscount += entry.price;
            });
            if (services.discountPercent) {
              discount = (services.discountPercent / 100) * totalBeforeDiscount;
            }
            if (services.discountAmount) discount = services.discountAmount;
          }
          const servicesTotal = totalBeforeDiscount - discount;

          return (
            <div style={{ padding: 20 }}>
              <h1>Patient Checkout</h1>
              <Paper style={{ padding: 20 }}>
                <p>
                  <strong>Name: </strong>
                  {appointment.patient.name}
                </p>

                <h2>Services opted</h2>
                {services ? (
                  <>
                    <table>
                      <thead>
                        <tr style={{ borderBottom: '2px solid #BBBBBB' }}>
                          <th style={{ padding: '5px 10px' }}>Service</th>
                          <th style={{ padding: '5px 10px' }}>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {services.services.map((entry) => (
                          <tr key={entry.id}>
                            <td style={{ padding: '5px 10px' }}>{entry.title}</td>
                            <td style={{ padding: '5px 10px', textAlign: 'right' }}>
                              {getCurrency(entry.price)}
                            </td>
                          </tr>
                        ))}
                        <tr style={{ borderTop: '1px solid #BBBBBB' }}>
                          <th style={{ padding: '5px 10px', textAlign: 'right' }}>Discount</th>
                          <td style={{ padding: '5px 10px', textAlign: 'right' }}>
                            {getCurrency(discount)}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ padding: '5px 10px', textAlign: 'right' }}>Total</th>
                          <td style={{ padding: '5px 10px', textAlign: 'right' }}>
                            {getCurrency(servicesTotal)}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <Form layout="inline" style={{ marginTop: 20 }}>
                      <FormItem hasFeedback label="SARAL Invoice Number">
                        {getFieldDecorator('saralInvoiceNumber', {
                          rules: [{ required: servicesTotal > 0, message: 'Field is required!' }],
                        })(<Input type="text" required />)}
                      </FormItem>
                    </Form>
                  </>
                ) : (
                  <p>No service taken!</p>
                )}

                <Mutation
                  mutation={APPT_FULFILL_MUTATION}
                  variables={{ appointmentId: id }}
                  context={{ headers: { 'x-hasura-role': 'receptionist' } }}
                >
                  {(fulfillAppointment) => (
                    <AntBtn
                      type="primary"
                      style={{ marginTop: 15 }}
                      // disabled={totalBeforeDiscount > 0}
                      onClick={() => {
                        validateFieldsAndScroll((err, values) => {
                          if (!err) {
                            fulfillAppointment({
                              variables: { saralInvoiceNumber: values.saralInvoiceNumber },
                              context: { headers: { 'x-hasura-role': 'receptionist' } },
                            })
                              .then(() => {
                                resetFields();
                                message.success('Appointment checked out');
                                history.push(
                                  match.url.includes('doctor') ? '/doctor' : '/receptionist',
                                );
                              })
                              .catch((mutateErr) => {
                                console.log(mutateErr);
                                message.error(mutateErr.message, 5);
                                Sentry.captureException(mutateErr);
                              });
                          }
                        });
                      }}
                    >
                      Complete Checkout
                    </AntBtn>
                  )}
                </Mutation>
                <Mutation
                  mutation={REVISE_MUTATION}
                  variables={{ appointmentId: id }}
                  context={{ headers: { 'x-hasura-role': 'receptionist' } }}
                >
                  {(appointmentRevise) => (
                    <AntBtn
                      type="primary"
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        appointmentRevise()
                          .then(() => {
                            history.push(
                              match.url.includes('doctor') ? '/doctor' : '/receptionist',
                            );
                          })
                          .catch((mutateErr) => {
                            console.log(mutateErr);
                            message.error(mutateErr.message);
                            Sentry.captureException(mutateErr);
                          });
                      }}
                    >
                      Revise Services
                    </AntBtn>
                  )}
                </Mutation>
              </Paper>
            </div>
          );
        }}
      </Query>
    );
  }
}

PatientReceptionistCheckout.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      appointmentId: PropTypes.string.isRequired,
    }).isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
};

export default Form.create()(PatientReceptionistCheckout);
