import React from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

const FloatingSaveBtn = ({ handleSave }) => (
  <MuiButton
    variant="raised"
    size="small"
    color="primary"
    style={{
      minWidth: 50,
      width: 50,
      height: 50,
      borderRadius: '50%',
      padding: 10,
      position: 'fixed',
      right: 10,
      bottom: 10,
      zIndex: 10000,
    }}
    onClick={handleSave}
  >
    <SaveIcon style={{ fontSize: 25 }} />
  </MuiButton>
);

FloatingSaveBtn.propTypes = {
  handleSave: PropTypes.func.isRequired,
};

export default FloatingSaveBtn;
