import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Query } from 'react-apollo';
import { Spin } from 'antd';

import QUERY from '../graphql/v2/queries/dietPlanQuery.graphql';
import ViewDietPlan from '../components/ViewDietPlan';

const DietPlanPrint = ({ match }) => (
  <Query
    query={QUERY}
    variables={{ id: match.params.id }}
    fetchPolicy="network-only"
    onError={(qErr) => {
      Sentry.captureException(qErr);
    }}
    context={{ headers: { 'x-hasura-role': 'receptionist' } }}
  >
    {({ loading, error, data }) => {
      if (loading) return <Spin size="large" />;
      if (error) return `Error! ${error.message}`;
      const { dietPlan } = data;
      return <ViewDietPlan patient={dietPlan.patient} dietPlan={dietPlan} />;
    }}
  </Query>
);

DietPlanPrint.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default DietPlanPrint;
