import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Query } from 'react-apollo';
import { Table } from 'antd';
import moment from 'moment';
import patientSaralInvoicesQuery from '../graphql/v2/queries/patientSaralInvoicesQuery.graphql';

const { Column } = Table;

const PatientSaralInvoicesComponent = ({ patientId }) => (
  <Query
    variables={{ patientId }}
    query={patientSaralInvoicesQuery}
    fetchPolicy="network-only"
    onError={(qErr) => {
      Sentry.captureException(qErr);
    }}
    context={{ headers: { 'x-hasura-role': 'receptionist' } }}
  >
    {({ loading, error, data }) => {
      if (error) return <p>ERROR: {error.message}</p>;

      return (
        <div style={{ width: 500 }}>
          <Table loading={loading} dataSource={data && data.invoices} bordered rowKey="id">
            <Column title="Invoice #" key="saralInvoiceNumber" dataIndex="saralInvoiceNumber" />
            <Column
              title="Date"
              key="date"
              dataIndex="date"
              render={(text) => moment(text, 'YYYY-MM-DD').format('Do MMM YYYY')}
            />
          </Table>
        </div>
      );
    }}
  </Query>
);

PatientSaralInvoicesComponent.propTypes = {
  patientId: PropTypes.number.isRequired,
};

export default PatientSaralInvoicesComponent;
