import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import withAuth from './auth/withAuth';
import AdminLayout from './layouts/AdminLayout';
import DoctorLayout from './layouts/DoctorLayout';
import ReceptionistLayout from './layouts/ReceptionistLayout';
import DietPlanPrint from './print/DietPlanPrint';

const App = ({ user }) => (
  <Switch>
    <Route
      path="/admin"
      render={(routeProps) => {
        if (user.role === 'admin') {
          return <AdminLayout {...routeProps} userName={user.name} />;
        }
        return <Redirect to="/" />;
      }}
    />
    <Route
      path="/doctor"
      render={(routeProps) => {
        if (user.role !== 'receptionist') {
          return <DoctorLayout {...routeProps} userName={user.name} />;
        }
        return <Redirect to="/" />;
      }}
    />
    <Route
      path="/receptionist"
      render={(routeProps) => <ReceptionistLayout {...routeProps} userName={user.name} />}
    />
    <Route path="/print/dietplan/:id" component={DietPlanPrint} />
    <Route
      render={() => {
        if (user && Object.prototype.hasOwnProperty.call(user, 'role')) {
          const { role } = user;
          if (role === 'admin') {
            return <Redirect to="/admin" />;
          }
          if (role === 'doctor') {
            return <Redirect to="/doctor" />;
          }
          if (role === 'receptionist') {
            return <Redirect to="/receptionist" />;
          }
        }
        return <Redirect to="/login" />;
      }}
    />
  </Switch>
);

App.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
};

export default withAuth(true)(App);
