import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import Paper from '@material-ui/core/Paper/Paper';

import TodayAppointmentList from '../components/TodayAppointmentList';
import DoctorPatientVisit from './DoctorPatientVisit';

const DoctorAppointment = (props) => {
  const { match } = props;
  const appointmentId = parseInt(match.params.appointmentId, 10);
  return (
    <div style={{ padding: 20 }} id="doctorAppointmentBox">
      <Row>
        <Col span={24}>
          <Paper className="muiPaperPrint">
            <Row>
              <Col span={4} style={{ padding: '10px 0' }} className="hideInPrint">
                <TodayAppointmentList selectedAppointment={appointmentId} />
              </Col>
              <Col
                span={20}
                style={{ padding: 20, borderLeft: '1px solid #f7e5e9' }}
                id="doctorAppointmentContentCol"
              >
                <DoctorPatientVisit appointmentId={appointmentId} />
              </Col>
            </Row>
          </Paper>
        </Col>
      </Row>
    </div>
  );
};

DoctorAppointment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      appointmentId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default DoctorAppointment;
