import React from 'react';
import PropTypes from 'prop-types';

import './CalendarDateButton.scss';

const daysOfWeek = {
  1: 'MON',
  2: 'TUE',
  3: 'WED',
  4: 'THU',
  5: 'FRI',
  6: 'SAT',
  7: 'SUN',
};

const CalendarDateButton = (props) => {
  const { selected, disabled, moment, clickHandler } = props;
  const classNames = ['calendar-date-button'];
  if (selected) classNames.push('calendar-date-button-isSelected');
  else if (disabled) classNames.push('calendar-date-button-isDisabled');
  else classNames.push('calendar-date-button-isOpen');

  return (
    <div
      className={classNames.join(' ')}
      role="button"
      onKeyPress={null}
      tabIndex="0"
      onClick={() => {
        if (!selected && !disabled) clickHandler(moment);
      }}
    >
      <p className="calendar-date-button-day">{daysOfWeek[moment.isoWeekday()]}</p>
      <p className="calendar-date-button-date">{moment.date()}</p>
    </div>
  );
};

CalendarDateButton.propTypes = {
  moment: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  clickHandler: PropTypes.func.isRequired,
};

CalendarDateButton.defaultProps = {
  selected: false,
  disabled: false,
};

export default CalendarDateButton;
