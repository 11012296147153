import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const Link = ({ children, ...props }) => (
  <RouterLink {...props} style={{ textDecoration: 'none', color: 'inherit' }}>
    {children}
  </RouterLink>
);

Link.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Link;
