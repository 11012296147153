import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { graphql } from 'react-apollo';
import { Modal, Form, Input, message } from 'antd';
import * as compose from 'lodash/flowRight';

import MUTATION from '../graphql/v2/mutations/updateAdminPasswordMutation.graphql';

const FormItem = Form.Item;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

class AdminUpdatePasswordForm extends Component {
  state = {
    submittingForm: false,
  };

  handleSubmit = () => {
    this.setState({
      submittingForm: true,
    });

    const { form, mutate, closeModal, user } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        mutate({
          variables: {
            userId: user.id,
            password: values.password,
          },
          context: { headers: { 'x-hasura-role': 'app_admin' } },
        })
          .then(({ data }) => {
            this.setState({
              submittingForm: false,
            });
            form.resetFields();
            message.success('Password updated!');
            closeModal(data.newPatient);
          })
          .catch((mutateErr) => {
            console.log(mutateErr);
            this.setState({
              submittingForm: false,
            });
            Sentry.captureException(mutateErr);
            message.error(mutateErr.message);
          });
      } else {
        this.setState({
          submittingForm: false,
        });
      }
    });
  };

  render() {
    const { form, closeModal, showModal, user } = this.props;
    const { getFieldDecorator } = form;
    const { submittingForm } = this.state;
    return (
      <Modal
        title="Update Password"
        visible={showModal}
        okText="Submit"
        onOk={this.handleSubmit}
        okButtonProps={{ loading: submittingForm }}
        onCancel={closeModal}
      >
        <Form layout="horizontal">
          <FormItem label="Email" {...formItemLayout}>
            <Input type="text" required disabled value={user.email} />
          </FormItem>
          <FormItem hasFeedback label="New Password" {...formItemLayout}>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Field is required!',
                },
              ],
            })(<Input type="text" disabled={submittingForm} />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

AdminUpdatePasswordForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default compose(graphql(MUTATION), Form.create())(AdminUpdatePasswordForm);
