import React from 'react';
import * as Sentry from '@sentry/browser';
import { Button, Card, Popconfirm, Table, Input, Icon } from 'antd';
import { Query, Mutation } from 'react-apollo';
import moment from 'moment';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper/Paper';
import Link from '../components/Link';
import UpdateSmsServiceComponent from '../components/UpdateSmsServiceComponent';

import AllActiveSubscriptionsQuery from '../graphql/v2/queries/allActiveSubscriptionsQuery.graphql';
import AllPausedSubscriptionsQuery from '../graphql/v2/queries/allPausedSubscriptionsQuery.graphql';
import PauseSubscriptionMutation from '../graphql/v2/mutations/pauseSubscriptionMutation.graphql';
import ResumeSubscriptionMutation from '../graphql/v2/mutations/resumeSubscriptionMutation.graphql';
import StopSubscriptionMutation from '../graphql/v2/mutations/stopSubscriptionMutation.graphql';
import AnamolySubscriptionsTable from '../components/AnamolySubscriptionsTable';

const { Column } = Table;

const handleSearch = (selectedKeys, confirm) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters();
};

const FilterDropdownComponent = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  name,
}) => (
  <div style={{ padding: 8 }}>
    <Input
      placeholder={`Search by ${name}`}
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={() => handleSearch(selectedKeys, confirm)}
      style={{ width: 188, marginBottom: 8, display: 'block' }}
    />
    <Button
      type="button"
      onClick={() => handleSearch(selectedKeys, confirm)}
      icon="search"
      size="small"
      style={{ width: 90, marginRight: 8 }}
    >
      Search
    </Button>
    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
      Reset
    </Button>
  </div>
);

const getColumnSearchProps = (dataIndex, name) => ({
  filterDropdown: (props) => <FilterDropdownComponent name={name} {...props} />,
  filterIcon: (filtered) => (
    <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value, record) =>
    record.patient[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
});

const DoctorSubscriptionsDashboardPage = () => (
  <div style={{ padding: 20 }}>
    <Paper>
      <Card title="Active Subscriptions">
        <Query
          query={AllActiveSubscriptionsQuery}
          fetchPolicy="network-only"
          onError={(qErr) => {
            Sentry.captureException(qErr);
          }}
          context={{ headers: { 'x-hasura-role': 'receptionist' } }}
        >
          {({ loading, error, data }) => {
            if (error) return <p>{error.message}</p>;
            return (
              <Table
                loading={loading}
                dataSource={data && data.allActiveSubscriptions}
                rowKey="id"
                bordered
              >
                <Column
                  title="Patient"
                  key="patient"
                  render={(text, record) => (
                    <div>
                      <p>{record.patient.name}</p>
                      <p>{record.patient.mobile}</p>
                    </div>
                  )}
                  {...getColumnSearchProps('name', 'name')}
                />
                <Column
                  title="Start Date"
                  key="start"
                  render={(text, record) =>
                    moment(new Date(record.startDate)).format('Do MMM, YYYY')
                  }
                />
                <Column title="Total Plans" key="dietPlansCount" dataIndex="dietPlansCount" />
                <Column
                  title="SMS Service"
                  key="sms"
                  render={(text, record) => (record.sendDietPlanSms ? 'ON' : 'OFF')}
                  filters={[
                    {
                      text: 'ON',
                      value: true,
                    },
                    {
                      text: 'OFF',
                      value: false,
                    },
                  ]}
                  onFilter={(value, record) => {
                    if (record.sendDietPlanSms === value) {
                      return record;
                    }
                    return null;
                  }}
                />
                <Column
                  title="Ongoing Diet Plan"
                  key="dietPlan"
                  render={(text, record) => {
                    if (
                      record.currentActiveDietPlanPosition &&
                      record.currentActiveDietPlanPosition <= record.dietPlans.length &&
                      record.currentActiveDietPlanPosition > 0
                    ) {
                      const dp = record.dietPlans[record.currentActiveDietPlanPosition - 1];
                      const dpSD = moment(new Date(dp.startDate));
                      return (
                        <div>
                          <p>
                            <strong>Diet Plan - {dp.subscriptionSequencePosition}</strong>
                          </p>
                          <p>
                            <strong>Start Date: </strong>
                            {dpSD.format('Do MMM, YYYY')}
                          </p>
                          <p>
                            <strong>End Date: </strong>
                            {moment(dpSD)
                              .add(dp.defaultValidityDays - 1, 'days')
                              .format('Do MMM, YYYY')}
                          </p>
                        </div>
                      );
                    }
                    return 'No active diet plan';
                  }}
                />
                <Column
                  title="Actions"
                  key="actions"
                  render={(text, record) => (
                    <div>
                      <Link to={`/doctor/subscription/${record.id}`}>
                        <Button htmlType="button">Edit Plans</Button>
                      </Link>
                      <UpdateSmsServiceComponent subscription={record} />
                      <Mutation
                        mutation={PauseSubscriptionMutation}
                        refetchQueries={['allActiveSubscriptions', 'allPausedSubscriptions']}
                        variables={{ id: record.id }}
                        onError={(mutErr) => {
                          Sentry.captureException(mutErr);
                        }}
                        context={{ headers: { 'x-hasura-role': 'doctor' } }}
                      >
                        {(pauseSubscription, { loading: mutLoading }) => (
                          <Button
                            htmlType="button"
                            onClick={pauseSubscription}
                            loading={mutLoading}
                          >
                            Pause Subscription
                          </Button>
                        )}
                      </Mutation>
                      {/* Stop Subscription action button */}
                      <Mutation
                        mutation={StopSubscriptionMutation}
                        refetchQueries={['allActiveSubscriptions']}
                        context={{ headers: { 'x-hasura-role': 'doctor' } }}
                        variables={{ id: record.id }}
                        onError={(mutErr) => {
                          Sentry.captureException(mutErr);
                        }}
                      >
                        {(stopSubscription, { loading: mutLoading }) => (
                          <Popconfirm
                            title={
                              // eslint-disable-next-line react/jsx-wrap-multilines
                              <span>
                                Are you sure you want to stop subscription?
                                <br />
                                <span style={{ color: 'red', fontWeight: 'bold' }}>
                                  CANNOT BE UNDONE
                                </span>
                              </span>
                            }
                            onConfirm={stopSubscription}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button htmlType="button" loading={mutLoading}>
                              Stop Subscription
                            </Button>
                          </Popconfirm>
                        )}
                      </Mutation>
                    </div>
                  )}
                />
              </Table>
            );
          }}
        </Query>
      </Card>
    </Paper>

    <Paper style={{ marginTop: 25 }}>
      <Card title="Paused Subscriptions">
        <Query
          query={AllPausedSubscriptionsQuery}
          fetchPolicy="network-only"
          onError={(qErr) => {
            Sentry.captureException(qErr);
          }}
          context={{ headers: { 'x-hasura-role': 'receptionist' } }}
        >
          {({ loading, error, data }) => {
            if (error) return <p>{error.message}</p>;
            return (
              <Table
                loading={loading}
                dataSource={data && data.allPausedSubscriptions}
                rowKey="id"
                bordered
              >
                <Column
                  title="Patient"
                  key="patient"
                  render={(text, record) => (
                    <div>
                      <p>{record.patient.name}</p>
                      <p>{record.patient.mobile}</p>
                    </div>
                  )}
                  {...getColumnSearchProps('name', 'name')}
                />
                <Column
                  title="Start Date"
                  key="start"
                  render={(text, record) =>
                    moment(new Date(record.startDate)).format('Do MMM, YYYY')
                  }
                />
                <Column title="Total Plans" key="dietPlansCount" dataIndex="dietPlansCount" />
                <Column
                  title="SMS Service"
                  key="sms"
                  render={(text, record) => (record.sendDietPlanSms ? 'ON' : 'OFF')}
                  filters={[
                    {
                      text: 'ON',
                      value: true,
                    },
                    {
                      text: 'OFF',
                      value: false,
                    },
                  ]}
                  onFilter={(value, record) => {
                    if (record.sendDietPlanSms === value) {
                      return record;
                    }
                    return null;
                  }}
                />
                <Column
                  title="Ongoing Diet Plan"
                  key="dietPlan"
                  render={(text, record) => {
                    if (
                      record.currentActiveDietPlanPosition &&
                      record.currentActiveDietPlanPosition <= record.dietPlans.length &&
                      record.currentActiveDietPlanPosition > 0
                    ) {
                      const dp = record.dietPlans[record.currentActiveDietPlanPosition - 1];
                      const dpSD = moment(new Date(dp.startDate));
                      return (
                        <div>
                          <p>
                            <strong>Start Date: </strong>
                            {dpSD.format('Do MMM, YYYY')}
                          </p>
                        </div>
                      );
                    }
                    return 'No active diet plan';
                  }}
                />
                <Column
                  title="Actions"
                  key="actions"
                  render={(text, record) => (
                    <div>
                      <Link to={`/doctor/subscription/${record.id}`}>
                        <Button htmlType="button">Edit Plans</Button>
                      </Link>
                      <Mutation
                        mutation={ResumeSubscriptionMutation}
                        refetchQueries={['allActiveSubscriptions', 'allPausedSubscriptions']}
                        variables={{ id: record.id }}
                        onError={(mutErr) => {
                          Sentry.captureException(mutErr);
                        }}
                        context={{ headers: { 'x-hasura-role': 'doctor' } }}
                      >
                        {(resumeSubscription) => (
                          <Button htmlType="button" onClick={resumeSubscription}>
                            Resume Subscription
                          </Button>
                        )}
                      </Mutation>
                      <Mutation
                        context={{ headers: { 'x-hasura-role': 'doctor' } }}
                        mutation={StopSubscriptionMutation}
                        refetchQueries={['allPausedSubscriptions']}
                        variables={{ id: record.id }}
                        onError={(mutErr) => {
                          Sentry.captureException(mutErr);
                        }}
                      >
                        {(stopSubscription, { loading: mutLoading }) => (
                          <Popconfirm
                            title={
                              // eslint-disable-next-line react/jsx-wrap-multilines
                              <span>
                                Are you sure you want to stop subscription?
                                <br />
                                <span style={{ color: 'red', fontWeight: 'bold' }}>
                                  CANNOT BE UNDONE
                                </span>
                              </span>
                            }
                            onConfirm={stopSubscription}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button htmlType="button" loading={mutLoading}>
                              Stop Subscription
                            </Button>
                          </Popconfirm>
                        )}
                      </Mutation>
                    </div>
                  )}
                />
              </Table>
            );
          }}
        </Query>
      </Card>
    </Paper>

    <Paper style={{ marginTop: 25 }}>
      <Card title="Anomaly Subscriptions">
        <AnamolySubscriptionsTable />
      </Card>
    </Paper>
  </div>
);

FilterDropdownComponent.propTypes = {
  setSelectedKeys: PropTypes.func.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  confirm: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default DoctorSubscriptionsDashboardPage;
