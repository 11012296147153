import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Checkbox, Col, Input, Radio, Row, InputNumber, message } from 'antd';
import { graphql } from 'react-apollo';
import Button from '@material-ui/core/Button';

import './DoctorPatientCheckoutForm.scss';

import MUTATION from '../graphql/v2/mutations/doctorCheckoutAppointmentMutation.graphql';

const { TextArea } = Input;
const RadioGroup = Radio.Group;

const baseBcaTestPrice = 1000;

const subscriptionPriceObj = {
  0: 0,
  1: 3000,
  4: 11000,
  8: 20000,
};

class DoctorPatientCheckoutForm extends Component {
  state = {
    consultationChecked: false,
    consultationPrice: 0,
    bcaTestChecked: false,
    bcaTestPrice: baseBcaTestPrice,
    subscriptionChecked: false,
    subscriptionPrice: 0,
    subscriptionDietPlansCount: 0,
    discountPercent: 0,
    discountAmount: 0,
    discountType: 'a',
    visitNotes: null,
  };

  constructor(props) {
    super(props);
    const newState = { ...this.state };
    const { visitNotes, services } = props.appointment;
    if (visitNotes) newState.visitNotes = visitNotes;
    if (services) {
      if (services.discountPercent > 0) {
        newState.discountPercent = services.discountPercent;
        newState.discountType = 'p';
      }
      if (services.discountAmount > 0) {
        newState.discountAmount = services.discountAmount;
        newState.discountType = 'a';
      }
      services.services.forEach((entry) => {
        if (entry.key === 'con') {
          newState.consultationChecked = true;
          newState.consultationPrice = entry.price;
        }
        if (entry.key === 'bca') {
          newState.bcaTestChecked = true;
          newState.bcaTestPrice = entry.price;
        }
        if (entry.isSubscription) {
          newState.subscriptionChecked = true;
          newState.subscriptionDietPlansCount = entry.dietPlansCount;
          newState.subscriptionPrice = entry.price;
        }
      });
    }
    this.state = newState;
  }

  handleSubmit = () => {
    const { appointment, mutate, refetchQuery } = this.props;
    const { discountType, discountAmount, discountPercent, ...stateData } = this.state;
    const dataToSubmit = {
      appointmentId: appointment.id,
      ...stateData,
      discountAmount: discountType === 'a' && discountAmount > 0 ? discountAmount : null,
      discountPercent: discountType === 'p' && discountPercent > 0 ? discountPercent : null,
    };

    mutate({
      variables: dataToSubmit,
      context: { headers: { 'x-hasura-role': 'doctor', 'x-hasura-doctor-id': '1' } },
    })
      .then(() => {
        refetchQuery();
        message.success('Success!');
      })
      .catch((mutateErr) => {
        console.log(mutateErr);
        message.error(mutateErr.message);
        Sentry.captureException(mutateErr);
      });
  };

  render() {
    const { hasActiveSubscription } = this.props;
    const {
      discountPercent,
      discountAmount,
      discountType,
      visitNotes,
      consultationPrice,
      bcaTestPrice,
      subscriptionPrice,
      consultationChecked,
      bcaTestChecked,
      subscriptionChecked,
      subscriptionDietPlansCount,
    } = this.state;

    let totalBeforeDiscount = 0;
    if (consultationChecked) totalBeforeDiscount += consultationPrice;
    if (bcaTestChecked) totalBeforeDiscount += bcaTestPrice;
    if (subscriptionChecked) totalBeforeDiscount += subscriptionPrice;
    let total = totalBeforeDiscount;
    if (discountType === 'a') total = totalBeforeDiscount - discountAmount;
    else total = totalBeforeDiscount * ((100 - discountPercent) / 100);

    return (
      <Fragment>
        <Row gutter={15}>
          <Col span={12}>
            <h2
              style={{
                marginBottom: 15,
              }}
            >
              Visit Notes
            </h2>
            <TextArea
              rows={5}
              value={visitNotes}
              onChange={(e) => this.setState({ visitNotes: e.target.value })}
            />
          </Col>

          <Col span={12}>
            <h2
              style={{
                marginBottom: 15,
              }}
            >
              Services & Subscription
            </h2>
            <table id="servicesTable">
              <tbody>
                <tr>
                  <td>
                    <Checkbox
                      checked={consultationChecked}
                      onChange={(e) => {
                        this.setState({
                          consultationChecked: e.target.checked,
                          consultationPrice: 0,
                        });
                      }}
                    />
                  </td>
                  <td style={{ color: consultationChecked ? 'inherit' : '#AAAAAA' }}>
                    Consultation
                  </td>
                  <td>
                    <InputNumber
                      value={consultationPrice}
                      style={{ width: 100 }}
                      disabled={!consultationChecked}
                      onChange={(value) => {
                        this.setState({
                          consultationPrice: value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      checked={bcaTestChecked}
                      onChange={(e) => {
                        this.setState({
                          bcaTestChecked: e.target.checked,
                          bcaTestPrice: baseBcaTestPrice,
                        });
                      }}
                    />
                  </td>
                  <td style={{ color: bcaTestChecked ? 'inherit' : '#AAAAAA' }}>BCA Test</td>
                  <td>
                    <InputNumber
                      value={bcaTestPrice}
                      style={{ width: 100 }}
                      disabled={!bcaTestChecked}
                      onChange={(value) => {
                        this.setState({
                          bcaTestPrice: value,
                        });
                      }}
                    />
                  </td>
                </tr>
                {!hasActiveSubscription ? (
                  <tr>
                    <td>
                      <Checkbox
                        checked={subscriptionChecked}
                        onChange={(e) => {
                          const val = e.target.checked;
                          if (val) {
                            this.setState({
                              subscriptionChecked: val,
                              subscriptionPrice: subscriptionPriceObj[1],
                              subscriptionDietPlansCount: 1,
                            });
                          } else {
                            this.setState({
                              subscriptionChecked: val,
                              subscriptionPrice: 0,
                              subscriptionDietPlansCount: 0,
                            });
                          }
                        }}
                      />
                    </td>
                    <td style={{ color: subscriptionChecked ? 'inherit' : '#AAAAAA' }}>
                      Subscription
                    </td>
                    <td colSpan={2}>
                      <RadioGroup
                        disabled={!subscriptionChecked}
                        value={subscriptionDietPlansCount}
                        onChange={(e) => {
                          this.setState({
                            subscriptionDietPlansCount: e.target.value,
                            subscriptionPrice: subscriptionPriceObj[e.target.value],
                          });
                        }}
                      >
                        <Radio style={{ display: 'block' }} value={1}>
                          Diet Plan (1x) - {subscriptionPriceObj[1]}
                        </Radio>
                        <Radio style={{ display: 'block' }} value={4}>
                          Diet Plan (4x) - {subscriptionPriceObj[4]}
                        </Radio>
                        <Radio style={{ display: 'block' }} value={8}>
                          Diet Plan (8x) - {subscriptionPriceObj[8]}
                        </Radio>
                      </RadioGroup>
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <td colSpan={2} style={{ textAlign: 'right' }}>
                    Discount
                  </td>
                  <td>
                    {discountType === 'a' ? (
                      <InputNumber
                        style={{ width: 100 }}
                        value={discountAmount}
                        min={0}
                        max={totalBeforeDiscount}
                        onChange={(value) => {
                          this.setState({ discountAmount: value });
                        }}
                      />
                    ) : (
                      <InputNumber
                        style={{ width: 100 }}
                        value={discountPercent}
                        min={0}
                        max={100}
                        onChange={(value) => {
                          this.setState({ discountPercent: value });
                        }}
                      />
                    )}
                    <RadioGroup
                      value={discountType}
                      onChange={(e) => {
                        this.setState({ discountType: e.target.value });
                      }}
                      style={{ marginLeft: 10 }}
                    >
                      <Radio value="p">%</Radio>
                      <Radio value="a">&#8377;</Radio>
                    </RadioGroup>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ textAlign: 'right', fontWeight: 700 }}>
                    TOTAL
                  </td>
                  <td style={{ fontWeight: 700 }}>&#8377; {total.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 20 }}
          onClick={this.handleSubmit}
        >
          CheckOut
        </Button>
      </Fragment>
    );
  }
}

DoctorPatientCheckoutForm.propTypes = {
  appointment: PropTypes.object.isRequired,
  hasActiveSubscription: PropTypes.bool.isRequired,
  mutate: PropTypes.func.isRequired,
  refetchQuery: PropTypes.func.isRequired,
};

export default graphql(MUTATION)(DoctorPatientCheckoutForm);
