import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { Query, useQuery } from 'react-apollo';
import { Spin } from 'antd';
import smsReportQuery from '../graphql/v2/queries/smsReportQuery.graphql';
import textlocalBalanceQuery from '../graphql/v2/queries/textLocalBalanceQuery.graphql';

const SmsReport = ({ startDate, endDate }) => {
  const balance = useQuery(textlocalBalanceQuery, {
    context: { headers: { 'x-hasura-role': 'doctor' } },
  });

  const getBalance = () => {
    const { loading, error, data } = balance;
    if (loading) return <Spin />;
    if (error) return 'NA';
    return data.textlocalBalance.balance;
  };

  return (
    <Query
      query={smsReportQuery}
      variables={{ startDate: moment(startDate), endDate: moment(endDate) }}
      fetchPolicy="network-only"
      onError={(qErr) => {
        Sentry.captureException(qErr);
      }}
      context={{ headers: { 'x-hasura-role': 'doctor' } }}
    >
      {({ loading, error, data }) => {
        if (loading) return <Spin />;
        if (error) return <p>ERROR: {error.message}</p>;
        const { smsReport } = data;
        const stats = {
          totalCount: smsReport.length,
          totalCost: 0,
          ntrtooCount: 0,
          ntrtooCost: 0,
          ntrmndCount: 0,
          ntrmndCost: 0,
        };
        smsReport.forEach((entry) => {
          const { cost, sender } = entry;
          stats.totalCost += cost;
          if (sender === 'NTRTOO') {
            stats.ntrtooCount += 1;
            stats.ntrtooCost += cost;
          }
          if (sender === 'NTRMND') {
            stats.ntrmndCount += 1;
            stats.ntrmndCost += cost;
          }
        });

        return (
          <div>
            <h2 style={{ marginBottom: 30 }}>
              SMS report from <strong>{startDate.format('Do MMMM, YYYY')}</strong> to{' '}
              <strong>{endDate.format('Do MMMM, YYYY')}</strong>
            </h2>
            <div style={{ marginBottom: 20, fontSize: 16 }}>
              <strong>Textlocal balance = </strong>
              {getBalance()}
            </div>
            <div style={{ display: 'flex' }}>
              <div>
                <h3 style={{ fontWeight: 700 }}>Total</h3>
                <p>
                  <strong>Count = </strong>
                  {stats.totalCount}
                </p>
                <p>
                  <strong>Cost = </strong>
                  {stats.totalCost}
                </p>
              </div>
              <div style={{ marginLeft: 30 }}>
                <h3 style={{ fontWeight: 700 }}>NTRTOO</h3>
                <p>
                  <strong>Count = </strong>
                  {stats.ntrtooCount}
                </p>
                <p>
                  <strong>Cost = </strong>
                  {stats.ntrtooCost}
                </p>
              </div>
              <div style={{ marginLeft: 30 }}>
                <h3 style={{ fontWeight: 700 }}>NTRMND</h3>
                <p>
                  <strong>Count = </strong>
                  {stats.ntrmndCount}
                </p>
                <p>
                  <strong>Cost = </strong>
                  {stats.ntrmndCost}
                </p>
              </div>
            </div>
          </div>
        );
      }}
    </Query>
  );
};

SmsReport.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
};

export default SmsReport;
