import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Popover } from 'antd';

import './BookingTimelineDay.scss';

const labels = [
  {
    hour: '09',
    minutes: '00',
    amPm: 'AM',
    halfHourMark: false,
  },
  {
    hour: '09',
    minutes: '30',
    amPm: 'AM',
    halfHourMark: true,
  },
  {
    hour: '10',
    minutes: '00',
    amPm: 'AM',
    halfHourMark: false,
  },
  {
    hour: '10',
    minutes: '30',
    amPm: 'AM',
    halfHourMark: true,
  },
  {
    hour: '11',
    minutes: '00',
    amPm: 'AM',
    halfHourMark: false,
  },
  {
    hour: '11',
    minutes: '30',
    amPm: 'AM',
    halfHourMark: true,
  },
  {
    hour: '12',
    minutes: '00',
    amPm: 'PM',
    halfHourMark: false,
  },
  {
    hour: '12',
    minutes: '30',
    amPm: 'PM',
    halfHourMark: true,
  },
  {
    hour: '01',
    minutes: '00',
    amPm: 'PM',
    halfHourMark: false,
  },
  {
    hour: '01',
    minutes: '30',
    amPm: 'PM',
    halfHourMark: true,
  },
  {
    hour: '02',
    minutes: '00',
    amPm: 'PM',
    halfHourMark: false,
  },
  {
    hour: '02',
    minutes: '30',
    amPm: 'PM',
    halfHourMark: true,
  },
  {
    hour: '03',
    minutes: '00',
    amPm: 'PM',
    halfHourMark: false,
  },
  {
    hour: '03',
    minutes: '30',
    amPm: 'PM',
    halfHourMark: true,
  },
  {
    hour: '04',
    minutes: '00',
    amPm: 'PM',
    halfHourMark: false,
  },
  {
    hour: '04',
    minutes: '30',
    amPm: 'PM',
    halfHourMark: true,
  },
  {
    hour: '05',
    minutes: '00',
    amPm: 'PM',
    halfHourMark: false,
  },
  {
    hour: '05',
    minutes: '30',
    amPm: 'PM',
    halfHourMark: true,
  },
  {
    hour: '06',
    minutes: '00',
    amPm: 'PM',
    halfHourMark: false,
  },
  {
    hour: '06',
    minutes: '30',
    amPm: 'PM',
    halfHourMark: true,
  },
  {
    hour: '07',
    minutes: '00',
    amPm: 'PM',
    halfHourMark: false,
  },
  {
    hour: '07',
    minutes: '30',
    amPm: 'PM',
    halfHourMark: true,
  },
  {
    hour: '08',
    minutes: '00',
    amPm: 'PM',
    halfHourMark: false,
  },
  {
    hour: '08',
    minutes: '30',
    amPm: 'PM',
    halfHourMark: true,
  },
  {
    hour: '09',
    minutes: '00',
    amPm: 'PM',
    halfHourMark: false,
  },
];

const timelineColors = {
  booked: '#b10029',
  blocked: '#999999',
  open: '#00FF00',
  outOfTiming: '#efccd4',
  fulfilled: '#000000',
};

class BookingTimelineDay extends Component {
  // eslint-disable-next-line class-methods-use-this
  bookingSlotPopover = (entry) => {
    const startTime = entry.startTime.format('hh:mm A');

    if (entry.type === 'fulfilled') return <span>Appointment completed</span>;
    if (entry.type === 'booked') return <span>Booked</span>;
    if (entry.type === 'blocked') return <span>Blocked by doctor</span>;
    if (entry.type === 'open') return `Book appointment from ${startTime}`;
    return (
      <div>
        <p style={{ color: '#FF0000', fontSize: '1.1rem', fontWeight: 'bold' }}>
          Outside doctor&#39;s timing
        </p>
        <p>Book appointment from {startTime}</p>
      </div>
    );
  };

  render() {
    const { componentWidth, slotsState, tickWidth, noOfHours, slotClickHandler } = this.props;

    const fifteenMinSlotWidth = Math.floor((componentWidth - 2 - 30) / (noOfHours * 4));
    const tickMarginLeft = fifteenMinSlotWidth - tickWidth;
    const timelineWidth = fifteenMinSlotWidth * noOfHours * 4 + 2;

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: timelineWidth + 30 }}>
          {/* Timeline */}
          <div className="bookingTimelineDayLine" style={{ width: timelineWidth, marginLeft: 14 }}>
            {slotsState.map((entry) => (
              <Popover
                key={entry.startTime.toISOString()}
                placement="topLeft"
                content={this.bookingSlotPopover(entry)}
              >
                <div
                  style={{
                    width: fifteenMinSlotWidth,
                    height: '100%',
                    backgroundColor: timelineColors[entry.type],
                    cursor:
                      entry.type === 'booked' ||
                      entry.type === 'fulfilled' ||
                      entry.type === 'blocked'
                        ? 'default'
                        : 'pointer',
                  }}
                  className={
                    entry.active ? 'bookingTimelineDaySlot activeSlot' : 'bookingTimelineDaySlot'
                  }
                  role="button"
                  onKeyPress={null}
                  tabIndex="0"
                  onClick={() => {
                    if (
                      entry.type !== 'booked' &&
                      entry.type !== 'blocked' &&
                      entry.type !== 'fulfilled'
                    )
                      slotClickHandler(entry.id);
                  }}
                />
              </Popover>
            ))}
          </div>
          {/* Ticks */}
          <div style={{ display: 'flex', width: timelineWidth, marginLeft: 14 }}>
            <div className="bookingTimelineDayTick bookingTimelineDayTickL" />
            {_.range(12).map((value) => [
              <div
                key={`${value}_1`}
                className="bookingTimelineDayTick bookingTimelineDayTickS"
                style={{ marginLeft: tickMarginLeft }}
              />,
              <div
                key={`${value}_2`}
                className="bookingTimelineDayTick bookingTimelineDayTickM"
                style={{ marginLeft: tickMarginLeft }}
              />,
              <div
                key={`${value}_3`}
                className="bookingTimelineDayTick bookingTimelineDayTickS"
                style={{ marginLeft: tickMarginLeft }}
              />,
              <div
                key={`${value}_4`}
                className="bookingTimelineDayTick bookingTimelineDayTickL"
                style={{ marginLeft: tickMarginLeft }}
              />,
            ])}
          </div>
          {/* Labels */}
          <div className="bookingTimelineDayLabelBox">
            {labels.map((entry, index) => {
              let labelMarginLeft = fifteenMinSlotWidth * 2 - 30;
              if (componentWidth < 1000) labelMarginLeft = fifteenMinSlotWidth * 4 - 30;

              if (index === 0) {
                return (
                  <div
                    key={`${entry.hour}${entry.minutes}${entry.amPm}`}
                    className="bookingTimelineDayLabel"
                  >
                    <p>
                      {entry.hour}:{entry.minutes}
                    </p>
                    <p className="bookingTimelineDayLabelAmPm">{entry.amPm}</p>
                  </div>
                );
              }
              if (componentWidth > 1000 || (componentWidth < 1000 && !entry.halfHourMark)) {
                return (
                  <div
                    key={`${entry.hour}${entry.minutes}${entry.amPm}`}
                    className="bookingTimelineDayLabel"
                    style={{ marginLeft: labelMarginLeft }}
                  >
                    <p>
                      {entry.hour}:{entry.minutes}
                    </p>
                    <p className="bookingTimelineDayLabelAmPm">{entry.amPm}</p>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    );
  }
}

BookingTimelineDay.propTypes = {
  componentWidth: PropTypes.number.isRequired,
  slotsState: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      startTime: PropTypes.object,
      type: PropTypes.oneOf(['open', 'booked', 'blocked', 'outOfTiming', 'fulfilled']),
      active: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  tickWidth: PropTypes.number,
  noOfHours: PropTypes.number,
  slotClickHandler: PropTypes.func.isRequired,
};

BookingTimelineDay.defaultProps = {
  tickWidth: 2,
  noOfHours: 12,
};

export default BookingTimelineDay;
