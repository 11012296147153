import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { graphql } from 'react-apollo';
import * as compose from 'lodash/flowRight';
import { Form, Input, InputNumber, Button, message, Select, Spin, Row, Col } from 'antd';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { units } from '../utils/globals';

import QUERY from '../graphql/v2/queries/allIngredientsQuery.graphql';
import MUTATION from '../graphql/v2/mutations/newRecipeMutation.graphql';

const FormItem = Form.Item;
const { Option } = Select;

class NewRecipeForm extends Component {
  state = {
    submittingForm: false,
    ingredientsKeys: [],
    ingredientsNextKey: 0,
  };

  addIngredient = () => {
    const { ingredientsKeys, ingredientsNextKey } = this.state;
    this.setState({
      ingredientsKeys: [...ingredientsKeys, ingredientsNextKey],
      ingredientsNextKey: ingredientsNextKey + 1,
    });
  };

  removeIngredient = (key) => {
    const { ingredientsKeys } = this.state;
    this.setState({
      ingredientsKeys: ingredientsKeys.filter((entry) => entry !== key),
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      submittingForm: true,
    });

    const { form, mutate } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { ingredientsKeys } = this.state;
        const valuesToSubmit = { ...values, tags: `{${values.tags.toString()}}` };
        if (ingredientsKeys.length > 0) {
          valuesToSubmit.ingredients = ingredientsKeys.map((entry) => values.ingredients[entry]);
        }
        mutate({ variables: valuesToSubmit, context: { headers: { 'x-hasura-role': 'doctor' } } })
          .then(() => {
            form.resetFields();
            this.setState({
              submittingForm: false,
              ingredientsKeys: [],
              ingredientsNextKey: 0,
            });
            message.success('Recipe added!');
          })
          .catch((mutateErr) => {
            console.log(mutateErr);
            this.setState({
              submittingForm: false,
            });
            message.error(mutateErr.message);
            Sentry.captureException(mutateErr);
          });
      } else {
        this.setState({
          submittingForm: false,
        });
      }
    });
  };

  render() {
    const { data, form } = this.props;
    if (data.loading) return <Spin />;
    const { allIngredients } = data;
    const { getFieldDecorator } = form;
    const { submittingForm, ingredientsKeys } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem hasFeedback label="Name of recipe">
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'First name is required!',
              },
            ],
          })(<Input type="text" required disabled={submittingForm} />)}
        </FormItem>
        <FormItem label="Description">
          {getFieldDecorator('description')(
            <Input.TextArea disabled={submittingForm} autosize={{ minRows: 2, maxRows: 4 }} />,
          )}
        </FormItem>
        <FormItem hasFeedback label="Quantity Unit">
          {getFieldDecorator('unit', {
            rules: [
              {
                required: true,
                message: 'First name is required!',
              },
            ],
          })(
            <Select disabled={submittingForm} showSearch>
              {units.map((entry) => (
                <Option value={entry.key} key={entry.key}>
                  {entry.name} ({entry.key})
                </Option>
              ))}
            </Select>,
          )}
        </FormItem>
        <FormItem hasFeedback label="Standard Quantity">
          {getFieldDecorator('stdQtyUnit', {
            rules: [
              {
                required: true,
                message: 'First name is required!',
              },
              {
                pattern: validationRegex.float,
                message: 'Incorrect value!',
              },
            ],
          })(<InputNumber required disabled={submittingForm} />)}
        </FormItem>
        <h3>Ingredients</h3>
        <Button
          htmlType="button"
          onClick={this.addIngredient}
          disabled={submittingForm}
          size="small"
        >
          Add Ingredient
        </Button>
        {ingredientsKeys.map((key) => (
          <Row gutter={20} style={{ marginTop: 10 }} key={key}>
            <Col span={6}>
              <FormItem label="Ingredient">
                {getFieldDecorator(`ingredients[${key}].ingredientId`, {
                  rules: [{ required: true, message: 'Field is required!' }],
                })(
                  <Select disabled={submittingForm}>
                    {allIngredients &&
                      allIngredients.map((entry) => (
                        <Option key={entry.id} value={entry.id}>
                          {entry.name_en}
                        </Option>
                      ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem hasFeedback label="Quantity">
                {getFieldDecorator(`ingredients[${key}].quantity`, {
                  rules: [
                    { required: true, message: 'Field is required!' },
                    {
                      pattern: validationRegex.float,
                      message: 'Incorrect value!',
                    },
                  ],
                })(<InputNumber required disabled={submittingForm} />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <Button
                htmlType="button"
                size="small"
                type="danger"
                onClick={() => this.removeIngredient(key)}
                disabled={submittingForm}
              >
                Remove ingredient
              </Button>
            </Col>
          </Row>
        ))}
        <hr />
        <br />
        <FormItem hasFeedback label="Total Carbohydrates">
          {getFieldDecorator('carbs', {
            rules: [
              {
                required: true,
                message: 'First name is required!',
              },
              {
                pattern: validationRegex.float,
                message: 'Incorrect value!',
              },
            ],
          })(<InputNumber required disabled={submittingForm} />)}
        </FormItem>
        <FormItem hasFeedback label="Total Fats">
          {getFieldDecorator('fats', {
            rules: [
              {
                required: true,
                message: 'First name is required!',
              },
              {
                pattern: validationRegex.float,
                message: 'Incorrect value!',
              },
            ],
          })(<InputNumber required disabled={submittingForm} />)}
        </FormItem>
        <FormItem hasFeedback label="Total Proteins">
          {getFieldDecorator('proteins', {
            rules: [
              {
                required: true,
                message: 'First name is required!',
              },
              {
                pattern: validationRegex.float,
                message: 'Incorrect value!',
              },
            ],
          })(<InputNumber required disabled={submittingForm} />)}
        </FormItem>
        <FormItem hasFeedback label="Total Calories (kcal)">
          {getFieldDecorator('kcal', {
            rules: [
              {
                required: true,
                message: 'First name is required!',
              },
              {
                pattern: validationRegex.float,
                message: 'Incorrect value!',
              },
            ],
          })(<InputNumber required disabled={submittingForm} />)}
        </FormItem>
        <FormItem label="Tags">
          {getFieldDecorator('tags')(<Select disabled={submittingForm} mode="tags" />)}
        </FormItem>
        <FormItem label="Cooking Instructions">
          {getFieldDecorator('cookingInstructions')(
            <Input.TextArea disabled={submittingForm} autosize={{ minRows: 2, maxRows: 4 }} />,
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" loading={submittingForm}>
            Submit
          </Button>
        </FormItem>
      </Form>
    );
  }
}

NewRecipeForm.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    allIngredients: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    ),
  }).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default compose(
  graphql(MUTATION),
  graphql(QUERY, {
    options: { fetchPolicy: 'network-only', context: { headers: { 'x-hasura-role': 'doctor' } } },
  }),
  Form.create(),
)(NewRecipeForm);
