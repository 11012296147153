import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { withApollo } from 'react-apollo';
import localforage from 'localforage';
import authToken from './authToken';
import { AuthProvider } from './authContext';
import { log } from '../utils/globals';

class AuthProviderComponent extends Component {
  constructor(props) {
    super(props);
    const token = '';
    this.state = {
      user: null,
      token,
      loading: !!props.persistAuth,
    };

    authToken.setToken(token);
  }

  componentDidMount() {
    const { persistAuth } = this.props;
    if (persistAuth) {
      localforage
        .getItem('user')
        .then((user) => {
          if (user) {
            this.updateUser(user);
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          log(err);
          Sentry.captureException(err);
        });
    }
  }

  updateUser = ({ token, ...user }) => {
    Sentry.configureScope((scope) => {
      scope.setUser({
        email: user.email,
      });
    });
    this.setState({ user, token, loading: false }, () => {
      authToken.setToken(token);
    });
  };

  login = (user) => {
    const { persistAuth } = this.props;
    // Update the state with user data
    this.updateUser(user);

    // Save auth data to local storage
    if (persistAuth) {
      localforage.setItem('user', user).catch((err) => {
        log(err);
        Sentry.captureException(err);
      });
    }
  };

  logout = () => {
    const { client } = this.props;
    // Reset auth state
    this.setState({ user: null, token: '' }, () => {
      authToken.removeToken();
    });
    // Reset apollo client
    client.resetStore();
    // Clear local storage
    localforage.clear().catch((err) => {
      log(err);
      Sentry.captureException(err);
    });
  };

  render() {
    const { user, loading, token } = this.state;
    const { children } = this.props;
    const authProviderValue = {
      userLoggedIn: !!user,
      loading,
      user,
      token,
      login: this.login,
      logout: this.logout,
    };
    return <AuthProvider value={authProviderValue}>{children}</AuthProvider>;
  }
}

AuthProviderComponent.propTypes = {
  client: PropTypes.object.isRequired,
  persistAuth: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

AuthProviderComponent.defaultProps = {
  persistAuth: true,
};

export default withApollo(AuthProviderComponent);
