import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Spin } from 'antd';
import Select from 'react-select';

import allPatientsQuery from '../graphql/v2/queries/allPatientsQuery.graphql';

const SearchPatientComponent = ({ history, match }) => (
  <Query
    query={allPatientsQuery}
    fetchPolicy="network-only"
    onError={(qErr) => {
      Sentry.captureException(qErr);
    }}
    context={{ headers: { 'x-hasura-role': 'receptionist' } }}
  >
    {({ loading, error, data }) => {
      if (loading) return <Spin />;
      if (error) return <p>ERROR: {error.message}</p>;
      const { allPatients } = data;
      return (
        <Fragment>
          <h3>Patient Search:</h3>
          <Select
            isClearable
            isSearchable
            onChange={(selectedOption) => {
              history.push(`${match.url}/patients/${selectedOption.value}`);
            }}
            options={allPatients.map((patient) => ({
              label: `${patient.name} (${patient.mobile})`,
              value: patient.id,
            }))}
          />
        </Fragment>
      );
    }}
  </Query>
);

SearchPatientComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(SearchPatientComponent);
