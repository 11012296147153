import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button/Button';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Header from '../components/Header';
import Dashboard from '../pages/AdminDashboard';
import Link from '../components/Link';
import AllPatientsPage from '../pages/AllPatientsPage';

const AdminLayout = (props) => {
  const { match, userName } = props;
  const { path } = match;

  return (
    <div id="body">
      <Header userName={userName} />
      <AppBar color="primary" position="static">
        <Toolbar style={{ minHeight: '30px' }}>
          <Link to="/admin">
            <Button color="default" style={{ color: '#FFFFFF' }}>
              Dashboard
            </Button>
          </Link>
          <Link to={`${path}/patients`}>
            <Button color="default" style={{ color: '#FFFFFF' }}>
              Patients
            </Button>
          </Link>
          <Button color="default" style={{ color: '#FFFFFF' }}>
            Reports
          </Button>
          <Button color="default" style={{ color: '#FFFFFF' }}>
            Settings
          </Button>
        </Toolbar>
      </AppBar>
      <Switch>
        <Route exact path={path} component={Dashboard} />
        <Route path={`${path}/patients`} component={AllPatientsPage} />
        <Redirect to={path} />
      </Switch>
    </div>
  );
};

AdminLayout.propTypes = {
  userName: PropTypes.string.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default AdminLayout;
